.nice-scroll {
  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $color-8;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $color-9;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $color-2;
  }

  &--small {
    &::-webkit-scrollbar {
      width: 6px;
    }
  }

  &--gray {
    &::-webkit-scrollbar-track {
      background: $gray-2;
    }
  }

  &--horizontal {
    &::-webkit-scrollbar {
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: $gray-2;
    }
  }
}

.toggle {
  width: 65px;
  border-radius: 5px;
  text-align: center;
  font-size: 11px;

  div {
    margin-bottom: 8px;
  }

  input {
    opacity: 0;
    position: absolute;

    & + label {
      position: relative;
      display: inline-block;
      user-select: none;
      transition: 0.4s ease;
      height: 30px;
      width: 60px;
      border: 1px solid $color-7;
      border-radius: 60px;
      cursor: pointer;

      &:before {
        content: "";
        position: absolute;
        display: block;
        height: 30px;
        width: 61px;
        top: 0;
        left: 0;
        border-radius: 30px;
        transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
      }

      &:after {
        content: "";
        position: absolute;
        display: block;
        box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 0 0 hsla(0, 0%, 0%, 0.04), 0 4px 9px hsla(0, 0%, 0%, 0.13),
          0 3px 3px hsla(0, 0%, 0%, 0.05);
        background: whitesmoke;
        height: 32px;
        width: 32px;
        top: -2px;
        left: 0;
        border-radius: 60px;
        transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
      }
    }

    &:checked {
      & + label:before {
        background: $color-2;
        transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
      }

      & + label:after {
        left: 34px;
      }
    }
  }
}

.btn {
  display: inline-block;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  padding: 10px 25px;
  border-radius: 5px;
  border: 1px solid $color-9;
  cursor: pointer;
  color: $color-9;
  text-transform: uppercase;
  position: relative;
  transition: all 0.3s;

  &:hover,
  &:focus,
  &--active {
    color: white !important;
    background-color: $color-9 !important;
    opacity: 0.9 !important;
  }

  &--yellow {
    border-color: $yellow;
    color: $yellow;

    &:hover,
    &:focus {
      background-color: $yellow !important;
    }
  }

  &--red {
    border-color: #e86f6f;
    color: #e86f6f;

    &:hover,
    &:focus {
      background-color: #e86f6f !important;
    }
  }

  &--tiny {
    font-size: 10px;
    padding: 3px 7px;
    border-radius: 3px;
  }

  &--small {
    font-size: 11px;
    padding: 6px 10px;
    border-radius: 3px;
  }

  &--large {
    width: 100%;
    max-width: 210px;
  }

  &--gradient {
    color: white;
    border: none;
    background-position: 0 100%;
    background-size: auto 200%;
    background-image: $gradient-3;

    &:hover {
      background-position: 0 0;
    }
  }

  &--shining {
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      top: -50%;
      right: -35%;
      bottom: -50%;
      left: -50%;
      background: linear-gradient(
        to bottom,
        rgba(229, 172, 142, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(229, 172, 142, 0)
      );
      transform: rotateZ(60deg) translate(-5em, 7.5em);
    }

    &:hover,
    &:focus {
      &::after {
        animation: sheen 1s forwards;
      }
    }
  }

  &--gray {
    border-color: $gray;
    color: $gray;
    position: relative;

    &:hover {
      border-color: $color-9;
      color: white;
      background-color: $color-9;
    }
  }

  &--icon {
    padding: 8px 20px 8px 30px;

    svg {
      position: absolute;
      left: 8px;
      top: 4px;
    }
  }
}

.loading-icon {
  width: 18px;
  position: relative;
  top: 1px;
  margin-right: 5px;
}

.text-field {
  position: relative;

  input {
    padding: 14px 55px 9px 33px !important;
    font-size: 12px;
  }

  &.addressInput {
    input {
      padding: 14px 55px 9px 14px !important;
      font-size: 12px;
    }
  }

  &__icon {
    position: absolute;
    top: 12px;
    left: 8px;

    img {
      width: 18px;
      height: 18px;
    }

    &--race {
      img {
        position: absolute;
        width: auto;
        height: 25px;
        top: -4px;
      }
    }
  }

  &__tag {
    color: white;
    background: $gray-2;
    position: absolute;
    cursor: pointer;
    border-radius: 3px;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    font-size: 10px;
    padding: 3px 8px;
  }

  &__fsp {
    margin: 0 2px 8px 0;
    font-size: 12px;
  }

  &__balance {
    font-size: 12px;
    margin: 8px 0 0 0;
    color: white;
  }

  &--read-only {
    input {
      padding-right: 75px !important;
      padding-left: 45px !important;
    }

    .text-field__icon {
      top: 16px;
      left: 12px;
    }
  }

  &--large {
    input {
      padding: 17px 55px 13px 38px !important;
    }

    .text-field__icon {
      top: 15px;
    }

    .text-field__tag {
      padding-bottom: 1px;
    }
  }

  &--default {
    input {
      padding: 18.5px 14px !important;
    }
  }

  .MuiFormLabel-root {
    color: $gray;
    transition: all 0.3s;
  }

  .MuiInputBase-root {
    color: inherit;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $gray;
  }

  .MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline,
  .MuiInputBase-formControl:hover .MuiInputLabel-formControl {
    border-color: $gray;
  }

  .Mui-focused {
    color: $color-9 !important;

    .MuiInputBase-input {
      color: $color-9 !important;
    }

    .MuiOutlinedInput-notchedOutline,
    .MuiInputLabel-formControl {
      border-color: $color-9 !important;
    }

    .MuiSelect-icon {
      color: $color-9;
    }
  }

  &--transfer {
    input {
      padding-left: 14px !important;
    }
  }

  &--dual-icon {
    input {
      padding: 13px 55px 9px 44px !important;
    }

    .text-field__icon img {
      width: 14px;
      height: 14px;
    }
  }
}

.tx-tracking {
  &__hash {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $bg;
    padding: 14px;
    border-radius: 5px;
  }

  &__content {
    text-align: center;
    margin-bottom: 20px;

    div:first-child {
      font-size: 14px;
      color: $gray;
      margin-bottom: 8px;
    }
  }

  @media screen and (max-width: $breakpoint-500) {
    &__hash {
      flex-direction: column;

      > div:first-child {
        margin-bottom: 10px;
      }

      > div:last-child {
        font-size: 12px;
      }
    }
  }
}

.dropdown {
  right: 0;
  top: initial !important;
  transform: initial !important;
  text-align: center;
  z-index: 99;
  margin: 15px auto 0;
  max-width: 200px;

  &__item {
    display: block;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 20px;
    cursor: pointer;
    border-radius: 7px;
    transition: all 0.3s;

    &:hover {
      background-color: $color-8;
    }
  }

  .MuiPaper-root {
    position: relative;
    border-radius: 5px;
    padding: 15px;
    background-color: $color-14;
    color: white;

    &::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 6px solid $color-14;
      position: absolute;
      top: -6px;
      right: 0;
      left: 0;
      margin: 0 auto;
    }
  }
}

.cm-table {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-family: $font-sub;
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 15px;
  }

  th,
  td {
    padding: 15px;
  }

  tr:last-child td {
    border-bottom: none;
  }

  &__no-record {
    width: 100%;
    text-align: center !important;
    position: absolute;
  }

  .MuiTableContainer-root {
    max-height: 300px;
    background-color: $color-8 !important;
    border-radius: 5px;
    overflow: auto;
    position: relative;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: $gray;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-6;
    }
  }

  &--empty .MuiTableContainer-root {
    min-height: 190px;
  }

  &--guild {
    tr:last-child td {
      border-bottom: 1px solid #987f5a !important;
    }

    [class*="Component"] {
      &::-webkit-scrollbar-track {
        background: #392d01;
      }

      &::-webkit-scrollbar-thumb {
        background: #987f5a;
        border: 0;
      }
      [style*="height"] {
        &::-webkit-scrollbar-track {
          background: #392d01;
        }

        &::-webkit-scrollbar-thumb {
          background: #987f5a;
          border: 0;
        }
      }
    }

    &-empty {
      min-height: 190px;
      background: linear-gradient(180deg, rgba(66, 52, 4, 0) 0%, #3a2e00 100%) !important;

      .cm-table__no-record {
        position: relative;
        height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media screen and (max-width: $breakpoint-600) {
    th,
    td {
      padding: 10px 15px;
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}

.inventory {
  height: 229px;
  border: 3px solid $color-8;
  border-right: 0;
  padding: 3% 1% 0 3%;
  width: 100%;
  background-color: #161815;
  overflow: auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  flex-flow: wrap;
  overflow-x: hidden;

  &--empty {
    height: 195px;
    padding: 3% 3% 0 3%;
    justify-content: space-evenly;
  }

  &--small {
    height: 165px;
    padding: 15px 15px 0;
  }
}

.detail-price {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;

  &__container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__dollars {
    font-family: $font-base;
    color: $gray;
    text-align: right;

    &--table {
      text-align: left;
    }
  }

  &__price {
    font-size: 3.2rem;
    color: $text;
    font-family: $font-sub;
  }

  &__logo {
    width: 65px;
    height: 65px;
    margin-left: 15px;
  }

  @media screen and (max-width: $breakpoint-959) {
    margin-top: 50px;
  }

  @media screen and (max-width: $breakpoint-600) {
    display: block;

    &__price {
      font-size: 30px;
    }

    &__dollars {
      font-size: 13px;
    }

    &__logo {
      width: 50px;
      height: 50px;
      margin-left: 10px;
    }
  }
}

.icon-input {
  display: inline-block;
  position: relative;

  &__icon {
    position: absolute;
    top: 5px;
    right: 5px;
    color: $gray;
  }

  &__input {
    width: 150px;
    color: white;
    background-color: $color-6;
    padding: 10px 35px 10px 10px;
    border-radius: 3px;
    font-size: 12px;
    border: 1px solid $gray-2;

    &::placeholder {
      color: $gray;
    }
  }
}

.plus-btn {
  border: 1px solid $color-9;
  color: $color-9;
  padding: 0 5px 1px;
  font-size: 12px;
  border-radius: 2px;
  margin-left: 10px;
  position: relative;
  bottom: 2px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    color: white;
    background-color: $color-9;
  }
}

.progress-bar {
  &__info {
    top: 10px;
    margin: 0 auto 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    font-size: 12px;
  }

  &__exp {
    display: flex;
    align-items: center;
    position: relative;
    top: 1px;

    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: -4px;
      font-weight: 500;
      width: 100%;
    }

    &-artisan {
      @extend .progress-bar__exp-title;
      align-items: flex-end;

      &--text {
        font-size: 14px;
        margin-bottom: 3px;
      }
    }
  }

  &__link {
    color: $color-9;
    position: relative;
    margin-right: 2px;
    cursor: pointer;
    bottom: 3px;

    &::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $color-9;
    }
  }

  &__bar {
    width: 100%;
    height: 5px;
    border-radius: 5px;
    margin: 0 auto;
    z-index: 1;
    background: $color-7;

    div {
      width: 0;
      height: 100%;
      position: relative;
      box-shadow: 0 2px 3px 0 #565656;
      transition: width 0.4s ease-out;
      border-radius: 5px;
      background-image: linear-gradient(20deg, #28cfff 0%, #b5f5b8 90%, white 100%);
    }

    &--full div {
      background-image: $gradient-7;
    }
  }
}

.body-part {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  column-gap: 20px;
  row-gap: 25px;
  line-height: normal;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 45%;
    gap: 15px;

    &--demi {
      @extend .body-part__item;
      justify-content: flex-start;
      width: 40%;
      margin: 0 20px 25px 0;
    }
  }

  &__content {
    margin-left: 15px;
    position: relative;
    top: 4px;
  }

  &__name {
    margin-bottom: 5px;
    font-weight: 500;
    font-family: $font-base;
  }

  &__rarity {
    font-size: 12px;
    font-weight: 600;
  }

  &__upgraded {
    width: 13px;
    position: relative;
    top: 3px;
    left: 5px;
  }

  &__btn {
    margin-left: 25px;
    position: relative;
    top: 10px;
    font-size: 9px;
  }

  &__rune {
    &-wrapper {
      padding: 5px;
      img {
        width: 20px;
        filter: invert(85%) sepia(6%) saturate(2878%) hue-rotate(104deg) brightness(104%) contrast(83%);
        transition: 0.2s;
      }

      &:hover {
        img {
          filter: invert(100%) sepia(39%) saturate(134%) hue-rotate(94deg) brightness(107%) contrast(101%);
        }
      }
    }

    &-equip {
      display: flex;
      justify-content: center;
      gap: 30px;
      align-items: center;
    }
  }

  &--column {
    flex-direction: column;

    .body-part__item {
      width: 100%;
      margin-right: 0;
    }
  }

  @media screen and (max-width: $breakpoint-600) {
    &__item {
      width: 48%;
      margin-right: 4%;

      &:nth-child(2n) {
        margin-right: 0;
      }

      img:first-child {
        width: 50px;
      }
    }

    &__name {
      width: 100%;
      font-size: 12px;
    }

    &__rarity {
      font-size: 12px;
    }

    &--column {
      .body-part__item {
        flex-wrap: wrap;
      }

      img:first-child {
        position: relative;
        top: 20px;
      }

      .body-part__content {
        width: calc(100% - 60px);
        margin-left: 10px;
      }

      .body-part__btn {
        margin-left: 60px;
        top: 10px;
      }
    }
  }
}

.dashed-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  text-align: center;
  text-transform: capitalize;
  position: relative;

  &::before {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 8px;
    border-top: 1px dashed grey;
  }

  span {
    padding: 0 15px;
    background-color: $color-8;
    position: relative;
    z-index: 2;
  }

  &--small {
    font-size: 12px !important;
    margin-bottom: 10px;
  }
}

.result-modal {
  margin-bottom: 35px;
  animation: slide-up 0.3s forwards;

  &__title {
    font-size: 20px;
    font-weight: 500;
  }

  &__sticker {
    width: 150px;

    &--human {
      margin-left: 35px;
    }
  }

  &__content {
    max-width: 320px;
    margin: 10px auto 0;
    text-align: center;
    color: $gray-3;
  }
}
