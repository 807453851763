.item-crafting {
  background: url($image-path + "crafting/background.png") center no-repeat;
  background-size: cover;
  padding: 100px 0;

  &__rune {
    &-tab {
      @extend .wallet__tab;
      margin: 0 20px 20px;
    }

    &-level-up {
      background-color: $color-8;
      padding: 30px 20px 20px;
      margin: 0 20px 20px;
    }
    &-type {
      font-family: $font-sub;
      font-size: 22px;
      margin: 20px 0;
    }
  }
  &__inventory {
    background-color: #081418;
    padding: 2% 1% 0 2%;

    &--item {
      height: fit-content;
    }
  }

  &__claim {
    max-width: 250px;
    width: 100%;
  }

  &__pity {
    &-reached {
      filter: drop-shadow(0px 1px 15px $color-epic);
    }
  }
  &__content {
    margin-top: 50px !important;

    &-navigation {
      width: 100%;
      position: relative;
      align-self: flex-start;
    }
  }

  &__items {
    width: 350px;
    margin: 0 auto;

    &-item {
      margin: 0 auto;
      width: 128px !important;
      height: 128px !important;
      cursor: pointer;
    }
  }

  &__history {
    &-image {
      max-width: 90px;
      max-height: 90px;
    }

    &-row {
      @extend .item-exchange__row;
      max-width: 650px !important;
      margin-left: -12px;

      .item-icon__amount {
        width: 27px;
        height: 27px;
        padding: 4px 0 0 7px;
        font-size: 12px;
        bottom: 4px;
        right: 1px;
      }
    }
  }

  &__slideshow {
    position: relative;
    width: 600px;
    height: 600px;

    &-item {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      max-width: 100%;

      &--hidden {
        visibility: hidden;
      }
    }
  }

  &__container {
    max-width: 420px;
    margin: 0 auto;
  }

  &__materials {
    &-row {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
    }

    &-chance {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 7px;
    }

    &-enhanced {
      cursor: pointer;

      img {
        width: 36px;
        height: 36px;
      }

      .item-icon__text {
        margin-top: 0;
        font-size: 10px !important;
      }
    }

    &-material {
      margin-right: 0 !important;
    }

    .MuiOutlinedInput-root {
      width: 100%;
    }
  }

  &__block {
    background-color: $color-8;
    padding: 15px;
    border-radius: 5px;
    max-width: 300px;
    margin: 0 auto;
  }

  &__bs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    &-icon {
      width: 33%;
    }

    &-content {
      width: 63%;
      padding-right: 10px;
    }

    &-title {
      font-weight: 500;
      margin-bottom: 5px;
    }

    &-desc {
      line-height: 1.6;
      font-size: 12px;
      margin-bottom: 10px;
    }

    .progress-bar {
      position: relative;
      bottom: 5px;
    }
  }

  &__rarity {
    &-level,
    &-image {
      max-width: 48px;
      max-height: 48px;
      z-index: 1;
    }

    &-level {
      font-size: 10px;
    }
  }

  &__recipe {
    &-list {
      margin: 10px 0;
      max-height: 302px;
      overflow-y: auto;
      overflow-x: hidden;

      &-option {
        display: flex;
        align-items: center;
        padding: 15px 15px 15px 20px;
        background-color: #575567;
        margin-bottom: 1px;
        font-size: 14px;
        font-weight: 500;

        &:hover {
          cursor: pointer;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:hover,
        &--active {
          position: relative;

          &::before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 6px;
            background-color: $yellow-2;
            content: "";
            display: block;
            animation: fade-in 0.3s forwards;
          }
        }
      }
    }
  }

  &__filter-container {
    display: flex;
    align-items: center;
  }

  &__race-filter {
    position: relative;
    z-index: 3;
  }

  &__filter {
    margin-top: 0 !important;
    transition: 0.3s;
    overflow: hidden;
  }

  &__icon {
    opacity: 0.4;
    transition: opacity 0.3s;

    &:hover,
    &--selected {
      opacity: 1;
    }
  }

  &__history {
    td {
      padding: 5px 15px;
    }
  }

  .dashed-title {
    font-size: 15px;
  }

  @media screen and (max-width: $breakpoint-1200) {
    &__content {
      margin-top: 0 !important;
      flex-direction: column;
      align-items: center;
    }

    &__slideshow {
      max-width: 90vw;

      img {
        max-width: 100%;
      }
    }

    &__filter {
      display: block !important;
    }

    &__race-filter {
      display: none;
    }

    &__filter-container {
      display: initial;
    }

    &__recipe-list {
      max-height: 178px;
    }
  }

  @media screen and (max-width: $breakpoint-600) {
    &__slideshow {
      height: 500px;
    }
  }

  @media screen and (max-width: $breakpoint-500) {
    &__slideshow {
      height: 450px;
    }
  }

  @media screen and (max-width: $breakpoint-414) {
    &__slideshow {
      height: 400px;
    }
  }

  @media screen and (max-width: $breakpoint-375) {
    &__slideshow {
      height: 350px;
    }
  }
}
