.skill-book {
  &__costs {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    .btn {
      border: 1px solid transparent;
      text-transform: none;
      font-size: 12px;
      color: $yellow;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;

      &:hover {
        background-color: transparent !important;
        border: 1px solid $color-9;
        .skill-book__costs-price {
          color: white;
        }
      }
      &-active {
        border: 1px solid $color-9;
      }
    }

    &-price {
      font-size: 36px;
      font-family: $font-sub;
      color: $color-9;
      position: relative;
      top: 5px;
      transition: 0.2s;
    }
  }
}
