.instructions {
  background: url($image-path + "/instructions/background.png") no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow-x: hidden;

  &-intro {
    padding-top: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__content {
      display: flex;
      flex-direction: column;
      margin-left: 50px;
      font-family: $font-base;
      text-align: center;

      h2 {
        color: #ffc904;
        font-family: $font-sub;
        font-size: 40px;
        margin-bottom: 30px;
        letter-spacing: 1px;
      }

      p {
        font-size: 16px;
        margin-bottom: 20px;
        line-height: 1.5;
        text-align: justify;
      }
    }

    &__image {
      width: 640px;
      height: 360px;
    }

    &__download {
      display: flex;
      justify-content: space-evenly;
      width: 100%;

      a {
        transition: 0.2s;

        &:hover {
          transform: translateY(5px);
        }
      }
    }
  }

  &-title {
    width: 100%;
    padding: 100px 0;

    h1 {
      margin: 0 auto;
      text-align: center;
      font-family: $font-sub;
      color: $yellow;
      font-size: 40px;
      letter-spacing: 2px;
    }
  }

  &-gameplay {
    &__interface {
      display: flex;
      flex-wrap: wrap;
      margin: 50px auto 0;
      justify-content: space-evenly;

      &-img {
        width: 100%;
      }

      &-map {
        width: 100%;

        &--mobile {
          width: 225px;
          margin-bottom: 50px;
        }
      }
    }
  }

  &-parts {
    &__desc {
      width: 50%;
      margin: 0 auto;
      line-height: 1.5;
      text-align: center;
      font-size: 16px;
    }

    &__ranking {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-content: space-around;
      margin: 50px auto 0;

      img {
        margin-bottom: 20px;
      }
    }

    &__image {
      width: 100%;
    }
  }

  &-types {
    background: url($image-path + "/instructions/type-bg.png") no-repeat center;
    background-size: cover;
    padding: 100px 0;

    &__image {
      width: 100%;
      margin-top: 20px;
    }

    &__advantages {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-content: space-around;
      margin: 50px auto 0;

      img {
        margin-bottom: 20px;
      }
    }

    &__desc {
      width: 50%;
      margin: 0 auto;
      line-height: 1.5;
      text-align: center;
      font-size: 16px;
    }
  }

  &-lineup {
    &__content {
      width: 80%;
      margin: 0 auto;
      line-height: 1.5;
      text-align: center;
      font-size: 16px;
    }

    &__image {
      width: 100%;

      &--homepage {
        width: unset;
        max-width: 100%;
      }
    }

    &__btn {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      transition: 0.2s;
      width: fit-content;

      img {
        width: 200px;
      }

      &:hover {
        transform: translateY(5px);
      }
    }
  }

  &-teams {
    &__image {
      width: 100%;
    }

    &__team {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      width: 100%;

      img {
        width: 380px;
        height: 100%;
      }
    }
  }

  &-demi {
    background: url($image-path + "/instructions/demi-bg.png") no-repeat bottom;
    background-size: cover;
    padding: 200px 0;

    &__image {
      width: 500px;
      position: relative;
      left: -20%;
      bottom: -100px;
    }

    &__content {
      text-align: end;
      margin-left: 50px;

      h2 {
        font-size: 40px;
        font-family: $font-sub;
        color: $yellow;
        margin-top: 20px;
        letter-spacing: 2px;
      }

      p {
        font-size: 14px;
        line-height: 2;
        font-style: italic;
      }
    }
  }

  &-soulstone {
    background: url($image-path + "/instructions/soulstone-wall.png") no-repeat center;
    background-size: cover;
    position: relative;
    z-index: 1;
    padding-bottom: 100px;

    &__rock {
      width: 2474px;
      height: 800px;
      left: -10%;
      position: absolute;
      top: -250px;
      z-index: 1;
    }

    .container {
      z-index: 99;
      padding-top: 150px;
    }

    #gate-instructions-player {
      z-index: 0;
      margin-top: 80px;
    }

    &__text {
      width: 70%;
      text-align: center;
      margin: 50px auto;
      font-size: 16px;
    }
  }

  &-crystal {
    &__usecase {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin: 50px auto;

      img {
        width: 250px;
        margin-bottom: 20px;
      }
    }
  }

  &-upcoming {
    padding-top: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

    &--map {
      justify-content: space-between;
    }

    &__content {
      display: flex;
      flex-direction: column;
      margin-left: 50px;
      font-family: $font-base;
      text-align: end;

      h2 {
        color: #ffc904;
        font-family: $font-sub;
        font-size: 40px;
        margin-bottom: 30px;
        letter-spacing: 2px;
      }

      p {
        font-size: 16px;
        margin-bottom: 20px;
        line-height: 1.5;
        text-align: end;
      }
    }

    &__image {
      width: 640px;
      height: 360px;

      &--type {
        width: 300px;
        height: auto;
        margin-right: 50px;
      }
    }

    &__terrain {
      width: 100%;
      margin-top: 50px;
    }

    &__download {
      display: flex;
      justify-content: space-evenly;
      width: 100%;

      a {
        transition: 0.2s;

        &:hover {
          transform: translateY(5px);
        }
      }
    }
  }

  &-guild {
    margin-top: 200px;
    margin-bottom: 100px;

    &__image {
      width: 40%;
    }

    &__content {
      text-align: start;

      h2 {
        font-size: 40px;
        font-family: $font-sub;
        color: $yellow;
        margin: 20px 0;
        letter-spacing: 2px;
      }

      p {
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }

  @media screen and (max-width: $breakpoint-1200) {
    &-intro {
      flex-direction: column;
      width: 80%;
      margin: 0 auto;

      &__content {
        align-items: center;
        margin: 50px 0;
      }

      &__image {
        width: 100%;
        height: auto;
      }
    }

    &-upcoming {
      flex-direction: column;
      width: 80%;
      margin: 0 auto;

      &--map {
        width: 100%;
      }

      &__content {
        align-items: center;
        margin: 50px 0;
        text-align: center;

        p {
          text-align: center;
        }
      }

      &__image {
        width: 100%;
        height: auto;

        &--crystal,
        &--type {
          display: none;
        }
      }
    }

    &-demi {
      .flex-start-center {
        flex-direction: column;
        align-items: center;
      }

      &__image {
        width: 50%;
        position: static;
      }

      &__content {
        width: 100%;
        text-align: justify;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;

        h2 {
          margin: 0 auto;
          text-align: center;
        }

        p {
          width: 90%;
        }

        img {
          margin: 50px auto;
        }
      }
    }

    &-guild {
      margin: 100px auto;

      .flex-start-center {
        flex-direction: column-reverse;
        align-items: center;
      }

      &__image {
        width: 50%;
      }

      &__content {
        text-align: center;
        margin-top: 50px;

        p {
          text-align: justify;
          width: 60%;
          margin: 0 auto;
        }

        &-image {
          display: none;
        }
      }

      .ml-7 {
        margin-left: 0 !important;
      }
    }

    &-soulstone {
      &__rock {
        width: 2000px;
        left: -60%;
      }
    }
  }

  @media screen and (max-width: $breakpoint-800) {
    &-soulstone {
      &__rock {
        height: 600px;
        left: -80%;
        top: -100px;
      }
    }
  }

  @media screen and (max-width: $breakpoint-700) {
    &-demi {
      &__image {
        width: 100%;
      }
    }

    &-guild {
      &__image {
        width: 100%;
      }
    }

    &-soulstone {
      &__rock {
        height: 500px;
        left: -80%;
        top: -100px;
      }

      #gate-instructions-player {
        margin-top: 35px;
      }

      &__text {
        width: 100%;
      }
    }

    &-intro {
      padding-top: 100px;
    }

    &-upcoming {
      padding-top: 100px;

      &--map {
        padding-top: 50px;
      }
    }

    &-types {
      &__advantages {
        width: 90%;

        img {
          width: 200px;
        }
      }

      &__desc {
        width: 90%;
      }
    }

    &-parts {
      &__ranking {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-content: space-around;
        margin: 50px auto 0;

        img {
          margin-bottom: 20px;
          width: 150px;
        }
      }

      &__desc {
        width: 90%;
      }
    }

    &-guild {
      &__content {
        p {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-600) {
    &-crystal {
      &__usecase {
        flex-direction: column;

        img:nth-child(odd) {
          align-self: flex-end;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-500) {
    &-soulstone {
      &__rock {
        left: -125%;
        top: -100px;
      }

      #gate-instructions-player {
        margin-top: 50px;
      }
    }

    &-intro {
      &__download {
        flex-direction: column;
        align-items: center;

        a {
          &:last-child {
            margin-top: 20px;
          }
        }
      }
    }

    &-types {
      &__advantages {
        width: 100%;

        img {
          width: 150px;
        }
      }
    }

    &-lineup {
      &__content {
        width: 100%;
        word-break: break-word;
      }
    }

    &-title {
      padding: 50px 0;
    }

    &-lineup {
      &__btn {
        img {
          width: 150px;
        }
      }
    }
  }
}