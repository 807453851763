.features {
  padding-top: 32px;
  position: relative;
  max-width: 100%;

  &__heading {
    text-align: center;
    position: relative;

    &-title {
      color: $yellow;
      font-family: $font-sub;
      font-size: 64px;
      font-weight: 400;
      letter-spacing: 0.018em;
      margin-bottom: 70px;
    }
  }

  &__container {
    display: flex;
    justify-content: left;
    gap: 10px;
    min-width: 0;
    padding-bottom: 100px;
    overflow-x: auto;
    touch-action: none;
    
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;

    & > img {
      margin-bottom: 100px;
    }
  }

  &__button {
    &--inactive {
      opacity: 0.5;
    }
  }

  &__feature {
    position: relative;

    &-content {
      position: absolute;
      bottom: 20px;
      left: 0;
      margin: 0 30px;
      height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &-title {
      font-family: $font-sub;
      font-size: 30px;
      line-height: 43px;
      letter-spacing: 0.018em;
    }

    &-desc {
      font-size: 11px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.018em;
      text-align: center;
      margin-bottom: 10px;
      color: $gray;
    }

    &-spacer {
      flex-grow: 1;
    }

    &-dropdown {
      position: relative;

      &:hover &--content {
        display: block;
      }

      &--button {
        width: 114px;
        cursor: pointer;
      }

      &--content {
        position: absolute;
        z-index: 1;
        display: none;
        font-size: 9px;
        font-weight: 600;
        line-height: 11px;
        text-align: center;
        top: 24px;
        
        &:hover {
          display: block;
        }
      }

      &--option {
        background: url($image-path + "/icons/features/features-detail-frame.png") no-repeat center;
        background-size: 100% 110%;
        padding: 8px;
        width: 113px;
        vertical-align: middle;
        opacity: 0.8;
        transition: opacity .2s;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-1300) {
    &__container {
      max-width: 875px;
    }
  }

  @media screen and (max-width: $breakpoint-1000) {
    padding-top: 10px;

    &__container {
      max-width: 581px;
      padding-bottom: 40px;
    }

    &__heading-title {
      margin-bottom: 20px;
    }
  }
  
  @media screen and (max-width: $breakpoint-700) {
    &__container {
      max-width: 287px;
    }

    &__wrapper {
      max-width: 100%;
      gap: 5px;
    }
  }
}