.data-summary {
  background: $bg;
  padding: 16px 0;
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to left, rgba(5, 5, 17, 1) 1%, rgba(255, 240, 165, 1) 50%, rgba(255, 239, 160, 0) 100%) 100% 0 100% 0/1px 0 1px 0 stretch;
  margin-bottom: 30px;

  &--guild {
    background: transparent;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;

    > div {
      margin: 0 10px;
    }
  }

  &__title {
    color: #52607b;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    font-family: $font-base;
  }

  &__value {
    color: white;
    font-size: 36px;
    line-height: 36px;
    margin-top: 8px;
    font-family: $font-sub;

    &--center {
      @extend .data-summary__value;
      text-align: center;
    }
  }

  @media screen and (max-width: $breakpoint-1000) {
    &__title {
      font-size: 16px;
      line-height: 16px;
    }

    &__value {
      font-size: 24px;
      line-height: 29px;
    }
  }

  @media screen and (max-width: $breakpoint-600) {
    margin-bottom: 50px;

    &__container {
      > div {
        margin-bottom: 20px;
      }
    }

    &__title, &__value {
      text-align: center;
    }

    &__value {
      margin-top: 5px;
    }
  }
}
