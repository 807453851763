.event {
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url($image-path + "/banners/fire-demon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  position: relative;

  &::before {
    content: "";
    display: block;
    height: 150px;
  }

  &::after {
    content: "";
    display: block;
    height: 220px;
  }

  &__sponsors {
    text-align: center;

    img {
      width: 90%;
    }
  }

  &__header {
    margin-top: 80px;
    margin-bottom: 50px;
    padding: 10px;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to left, #050511 1%, #fff0a5 50%, rgba(255, 239, 160, 0) 100%) 100% 0 100% 0/1px 0 1px 0 stretch;
    font-family: $font-sub;
    font-size: 72px;
    text-align: center;
    position: relative;

    span {
      color: $yellow;
    }

    &:first-child {
      margin-top: 0px;
    }
  }

  &__content {
    max-width: 950px;
    margin: 0 auto;
    text-align: center;
    line-height: 32px;
    letter-spacing: 0.018em;
    font-size: 16px;

    &-desc {
      text-indent: 100px;
      text-align: justify;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0.018em;
      color: #D4CBBE;
    }

    &-time {
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0.018em;
      text-align: center;
      color: $yellow;
    }


    &-collection {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding-bottom: 80px;

      img {
        min-width: 0;
      }

      &--viewer {
        position: relative;
      }

      &--viewer-desc {
        position: absolute;
        text-align: right;
        bottom: 20px;
        right: 25px;

        div {
          font-size: 18px;
          line-height: 25.74px;
          letter-spacing: 0.018em;
        }

        div:nth-child(2) {
          color: $yellow;
        }
      }

      &--thumbnail {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 5.5px;
      }

      &--wrapper {
        background: url($image-path + "/event/collections-thumbnail-frame.png") center no-repeat;
        background-size: 100% 100%;
        width: 19.5%;
        height: 10vw;
        max-height: 110px;

        div {
          overflow: hidden;
          height: 97%;
          margin: 2px;
        }

        img {
          width: 100%;
          cursor: pointer;
        }
      }
    }

    &-reward {
      max-width: 1000px;
      height: 550px;
      display: flex;
      align-items: flex-start;

      img {
        min-width: 0;
      }

      img:nth-child(1) {
        align-self: center;
      }

      img:nth-child(3) {
        align-self: flex-end;
      }
    }

    &-vote {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 200px;

      &--frame {
        background-image: url($image-path + "/event/voting-frame.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        padding: 40px;
        max-width: 332px;
        margin-bottom: 100px;
      }

      &--title {
        font: {
          family: $font-sub;
          size: 72px;
        }

        line-height: 102.96px;
        letter-spacing: 0.018em;
        margin-bottom: 40px;
      }

      &--desc {
        font-size: 16px;
        line-height: 22.88px;
        letter-spacing: 0.018em;
        text-align: justify;
        text-indent: 50px;
      }
    }
  }

  &__rule {
    display: flex;
    align-items: center;
    text-align: left;
    letter-spacing: 0.018em;
    color: #D4CBBE;
    margin-top: 20px;

    &--expand {
      align-items: flex-start;
    }

    &-index {
      position: relative;
      z-index: 1;

      div {
        position: absolute;
        font-size: 96px;
        font-family: $font-sub;
        text-align: center;
        left: 50%;
        top: 48%;
        transform: translate(-50%, -50%);
      }
    }

    &-title {
      color: $yellow;
      font-family: $font-sub;
      font-weight: 400;
      font-size: 48px;
      cursor: pointer;
    }

    &-more {
      font-weight: 400;
      font-size: 16px;
    }

    &-collapse {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      background: url($image-path + "/event/prize.png");
      box-shadow: inset 0px 4px 30px rgba(0, 0, 0, 0.5);
      mask-image: linear-gradient(90deg, #C4C4C4 67.56%, rgba(196, 196, 196, 0) 97.86%);
      height: 153px;
      width: 100%;
      margin-left: -70px;
      padding-left: 100px;
      cursor: pointer;
    }

    &-expand {
      background-image: url($image-path + "/event/rule-frame-top.png"), url($image-path + "/event/rule-frame-left.png"), url($image-path + "/event/prize.png");
      background-repeat: no-repeat, no-repeat, repeat;
      background-position: top left, top 60px left, top left;
      margin-top: 16px;
      padding: 50px 50px 50px 150px;
      margin-left: -120px;
      width: 100%;
    }

    &-content {
      text-align: justify;

      span {
        color: $yellow;
      }

      b {
        font-weight: 700;
      }
    }
  }

  &__hof {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;

    &-current {
      padding: 0;
      flex-basis: 120%;
    }

    &-rear {
      display: flex;
      align-items: center;
      flex-basis: 80%;

      &:nth-of-type(odd) {
        mask: linear-gradient(90deg, #D9D9D9 -4.23%, rgba(217, 217, 217, 0) 56.29%);
      }

      &:first-child {
        mask: linear-gradient(270deg, #D9D9D9 -4.23%, rgba(217, 217, 217, 0) 56.29%);
      }

      img {
        width: 100%;
      }
    }
  }

  &__collections {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    &-options {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      margin-bottom: 10px;
      font-size: 14px;

      &--sort {
        cursor: pointer;
      }
    }

    &-navbtn {
      width: 30px;
      cursor: pointer;
    }

    &-container {
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(3, 1fr);
    }

    &-item {
      position: relative;

      &--image {
        width: 100%;
        height: 150px;
        max-height: 150px;
        overflow: hidden;
        background-image: url($image-path + "/event/collections-thumbnail-frame.png");
        background-size: 100% 100%;
        border: 1px solid transparent;

        div {
          background-color: black;
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            cursor: pointer;
          }
        }
      }

      &--title {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
      }
    }

    &-closebtn {
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;
    }

    &-modal {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 98%;
      transform: translate(-50%, -50%);
      font-family: Montserrat;
      line-height: 24px;
      letter-spacing: 0.018em;
      width: 1300px;

      &:focus {
        outline: none;
      }

      &--image-container {
        text-align: center;
        flex: 1;
        height: 70vh;
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        &::-webkit-scrollbar {
          width: 3px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: $color-8;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $color-9;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $color-2;
        }

        &--center {
          justify-content: center;
        }
      }

      &--image {
        max-width: 60%;
      }

      &--navbtn {
        max-width: 20px;
        cursor: pointer;
      }

      &--left {
        flex-basis: 68%;
        gap: 15px;
        justify-content: center;
      }

      &--right {
        flex-basis: 35%;
        position: relative;
        margin-left: 10px;
        margin-top: 65px;
      }

      &--separator {
        position: absolute;
        left: -57%;
        top: 50%;
        background: linear-gradient(90deg, #2A2935 0%, #E8DB9A 22.35%, #E8DB9A 73.18%, #2A2935 100%);
        transform: rotate(90deg);
        height: 1px;
        width: 100%;
      }

      &--title {
        font-size: 20px;
        font-weight: 700;
        line-height: 28.6px;
        font-family: Montserrat;
      }

      &--subtitle {
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
        color: $yellow;
      }

      &--desc {
        margin: 15px 0;
        font-size: 14px;
        font-weight: 400;
        text-align: justify;
        letter-spacing: 0.018em;
        max-height: 30vh;
        overflow: auto;
        padding-right: 10px;

        &::-webkit-scrollbar {
          width: 3px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: $color-8;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $color-9;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $color-2;
        }
      }

      &--time {
        font-size: 10px;
        font-style: italic;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.018em;
        color: #9696A0;
      }

      &--vote {
        margin-top: 5px;
      }

      &--actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 5px;
        margin-top: 30px;
        font-size: 12px;

        &>* {
          display: flex;
          align-items: center;
          gap: 5px;
          background: url($image-path + "event/vote-btn.png") center;
          background-size: 100% 100%;
          padding: 5px 20px;
          cursor: pointer;
        }
      }
    }

    &-modal-container {
      background: url($image-path + "event/modal-main.png") no-repeat;
      background-size: 100% 100%;
      padding: 25px;
      margin-bottom: 10px;
      position: relative;
      gap: 30px;
    }
  }

  &__reward {
    max-width: 1200px;
    margin: 0 auto;

    &-text {
      max-width: 900px;
      margin: 0 auto;
      color: #D4CBBE;
    }

    &-title {
      font-family: $font-sub;
      font-style: normal;
      font-size: 38px;
      line-height: 38px;
      text-align: center;
      margin-top: 30px;
      margin-bottom: 10px;

      &--active {
        color: $yellow;
      }

      &--2 {
        margin-top: 50px;
        margin-left: -80px;
      }

      &--3 {
        margin-top: 40px;
      }
    }

    &-desc {
      font-size: 24px;
      font-weight: 700;
      color: #D4CBBE;

      &--2 {
        margin-left: -80px;
      }
    }

    &-subdesc {
      font-size: 14px;
      font-weight: 300;
      line-height: 28px;
      letter-spacing: 0.018em;
      text-align: center;
      max-width: 500px;
      margin: 0 auto;
    }

    &-wrapper {
      position: relative;
      margin-top: 120px;
    }

    &-prizes {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-left: 300px;
    }

    &-image {
      pointer-events: none;
      position: absolute;
      top: -45px;
      left: 180px;
      z-index: 1;
      max-width: 281px;
      width: 100%;

      &--1 {
        top: 233px;
        left: 300px;
        max-width: 230px;
      }

      &--2 {
        top: 448px;
        left: 209px;
        max-width: 200px;
      }

      &--decor {
        max-width: 400px;
        top: 30px;
        left: 140px;
      }
    }

    &-prize {
      height: 195px;
      background: url($image-path + "/event/prize.png");
      box-shadow: inset 0px 4px 30px rgba(0, 0, 0, 0.5);
      mask-image: linear-gradient(90deg, #C4C4C4 67.56%, rgba(196, 196, 196, 0) 97.86%);
      padding-left: 50px;

      &--active {
        border: 2px solid;
        border-image-source: linear-gradient(180deg, #7A7369 0%, #EAC48B 51.04%, #7A6969 100%);
        border-image-slice: 30 30% 45;
      }

      &:nth-child(2) {
        margin-top: 48px;
        margin-left: 100px;
      }

      &:nth-child(3) {
        margin-top: 20px;
        height: 150px;
      }
    }
  }

  &__timeline {
    img {
      width: 100%;
    }
  }

  &__judge {
    &-line {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;

      &--image {
        max-width: 10%;
        background: linear-gradient(180deg, #7A7369 0%, #EAC48B 51.04%, #7A6969 100%);
        box-shadow: inset 0px 4px 30px rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        padding: 2px;
        cursor: pointer;
      }
    }

    &-active {
      position: relative;
      z-index: 1;

      &--background {
        background: none;
        position: relative;
      }

      &--image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &-info {
      text-align: center;
      margin-top: -110px;
      position: relative;
      z-index: 0;

      img {
        width: 80%;
      }

      &--text {
        position: absolute;
        top: 0;
        left: 0;
        margin: 90px 170px;
      }

      &--title {
        font-size: 24px;
        font-weight: 500;
        color: $yellow;
      }

      &--desc {
        text-align: justify;
        line-height: 23px;
      }
    }
  }

  @media screen and (max-width: $breakpoint-1000) {
    padding: 20px;

    &__reward {
      &-prizes {
        margin-left: 50px;
      }

      &-image {
        left: -26px;

        &--decor {
          left: -80px;
        }

        &--1 {
          left: 50px;
        }
      }

      &-subdesc {
        max-width: 300px;
      }
    }

    &__hof {
      &-rear {
        display: none;
      }
    }
  }

  @media screen and (max-width: $breakpoint-959) {
    padding: 20px;

    &__content {
      &-desc {
        text-indent: 0;
      }

      &-rule {
        &--index {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-900) {
    &__content {
      &-vote {
        gap: 50px;
      }

      &-reward {
        height: 400px;
      }
    }

    &__collections {
      &-closebtn {
        position: absolute;
        top: 10px;
        right: 15px;
      }

      &-modal-container {
        flex-direction: column;
        gap: 20px;
        padding: 20px 10px;
      }

      &-modal {
        &--separator {
          display: none;
        }

        &--left {
          justify-content: center;
          gap: 15px;
        }

        &--right {
          margin-top: 0;
        }

        &--image-container {
          height: 30vh;
        }

        &--desc {
          height: 10vh;
        }
      }
    }

    &__judge {
      &-info {
        img {
          width: 100%;
        }

        &--text {
          margin: 90px 50px;
        }
      }
    }

    &__rule {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &-collapse,
      &-expand {
        padding: 20px;
        margin-left: 0;
        background-image: url($image-path + "/event/prize.png");
        background-size: 100% 100%;
      }

      &-title {
        font-size: 36px;
      }
    }
  }

  @media screen and (max-width: $breakpoint-700) {
    & .crystal {
      display: none;
    }

    &__content {
      &-rule {
        flex-direction: column;
        align-items: center;
      }

      &-reward {
        height: 300px;
      }

      &-vote {
        flex-direction: column;
        gap: 0;
      }
    }

    &__reward {
      &-title {
        &--2 {
          margin-left: 0;
        }
      }

      &-desc {
        &--2 {
          margin-left: 0;
        }
      }

      &-subdesc {
        max-width: 90%;
      }

      &-prizes {
        margin-left: 0;
      }

      &-prize {
        margin-left: 0 !important;
        padding-left: 0;
      }

      &-image {
        display: none;
      }
    }

    &__collections {
      &-container {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &__judge {
      &-line {
        position: relative;
        z-index: 1;
        gap: 0;
      }

      &-info {
        margin-top: 0px;
        overflow: hidden;

        &--text {
          margin: 10px 20px;
          overflow-y: scroll;
          max-height: 130px;
        }

        &--title {
          font-size: 20px;
        }

        &--desc {
          font-size: 12px;
          line-height: 16px;
        }
      }

      &-active {
        &--background {
          max-width: 50%;
          left: 25%;
        }

        &--image {
          max-width: 40%;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-600) {
    padding: 10px;

    &__content {
      &-reward {
        height: 200px;
      }
    }

    &__collections {
      &-container {
        display: block;
      }

      &-item {
        &--image {
          height: 220px;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-500) {
    &__collections {
      gap: 10px;
    }
  }

  @media screen and (max-width: $breakpoint-375) {
    &__content {
      &-collection {
        &--thumbnail {
          display: none;
        }
      }
    }

    &__collections {
      &-modal {
        &--title {
          font-size: 16px;
        }

        &--subtitle {
          font-size: 12px;
        }

        &--desc {
          font-size: 14px;
          margin-top: 5px;
        }
      }
    }
  }
}