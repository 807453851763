.footer {
  background: $color-6;
  padding: 40px 0 50px;

  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-size: 30px;
    font-family: $font-sub;
    margin-bottom: 15px;
  }

  &__content {
    width: calc(100% - 350px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-left {
      width: 300px;
    }
  }

  &__block {
    width: 32%;
    min-width: 160px;
    margin-bottom: 10px;
  }

  &__link {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    transition: color 0.3s;

    &:hover {
      color: $yellow;
    }
  }

  &__pancake,
  &__biswap {
    width: 100%;
    max-width: 120px;
    padding: 2px 10px;
    margin-top: 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: $color-17;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 500;

    img {
      width: 20px;
      margin-right: 5px;
    }
  }

  &__biswap {
    background-image: linear-gradient(
      to right,
      #1180ef,
      #217ae4,
      #3075d9,
      #406fce,
      #506ac2,
      #6064b7,
      #6f5fac,
      #7f59a1,
      #8f5396,
      #9e4e8b,
      #ae4880,
      #be4374,
      #ce3d69,
      #dd385e,
      #ed3253
    );
  }

  @media screen and (max-width: $breakpoint-1200) {
    &__container {
      display: block;
    }

    &__content {
      width: 100%;

      &-left {
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }

  @media screen and (max-width: $breakpoint-600) {
    &__block {
      width: 48%;
      min-width: auto;
    }
  }
}
