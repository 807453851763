.hero-burn {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin: 40px 0 30px;

  &__title {
    font-family: $font-sub;
    font-size: 40px;
    text-align: center;
    margin-bottom: 15px;
  }

  &__icon {
    margin-right: 7px;
    margin-bottom: 7px;
  }
  &__container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 25px;
    width: 50%;
    margin: 20px 0 20px 10px;
  }

  &__description {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__item-title {
    font-size: 40px;
    font-family: $font-sub;
    text-align: center;
  }

  &__selection {
    width: 90px;
    height: 100px;
    border: 2px solid #4c4c52;
    overflow: hidden;
    transition: .2s;
    
    &:hover, &--selected {
      background-color: $color-8;
      border-color: $color-17;
    }

    &--hero {
      img {
        width: 235px;
        position: relative;
        right: 70px;
        bottom: 15px;
      }
    }

    &--material {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 110px;
      height: 110px;
      overflow: hidden;
      margin-right: 20px;

      img {
        width: 60px;
        margin: auto;
      }

      p {
        font-size: 11px;
        padding: 0 5px 10px 5px;
        font-weight: 500;
      }
    }

    &-id {
      width: 100%;
      text-align: center;
      font-size: 14px;
      padding: 2px;
      position: absolute;
      bottom: 0;

      &.fire {
        background-color: $color-15;
      }

      &.water {
        background-color: $color-16;
      }

      &.grass {
        background-color: $color-11;
      }

      &.thunder {
        background-color: $color-13;
      }

      &.rock {
        background-color: $color-12;
      }

      &.ice {
        background-color: $color-10;
      }

      &.normal {
        background-color: $gray-2;
      }

      &.demi {
        background-color: $color-demi;
      }

      &.dead {
        background-color: $color-19;
      }
    }

    &--null {
      display: flex;
      font-size: 30px;
      color: #ababab;
      font-weight: 500;
      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: $breakpoint-1200) {
    flex-direction: column-reverse;
    align-items: center;
    row-gap: 30px;

    &__container {
      width: 60%;
      justify-content: center;
      align-items: center;
    }

    &__selection {
      &--material {
        margin-right: 0;
      }
    }

    &__description {
      width: 80%;
    }
  }

  @media screen and (max-width: $breakpoint-959) {
    &__container {
      width: 70%;
    }
  }

  @media screen and (max-width: $breakpoint-800) {
    &__container {
      width: 80%;
    }
  }
  @media screen and (max-width: $breakpoint-700) {
    &__container {
      width: 90%;
    }
  }

  @media screen and (max-width: $breakpoint-600) {
    &__container {
      width: 100%;
    }
  }
}
