.black-market {
  &__timer {
    &-countdown {
      font-family: $font-sub;
      font-size: 22px;
    }

    &-subtitle {
      font-size: 12px;
      color: $gray;
    }
  }

  &__modal {
    &-exchange {
      text-align: center;
      .warning-text {
        font-size: inherit;
        font-weight: 700;
      }
    }

    &-items {
      @extend .flex-center-center;
      flex-wrap: wrap;

      .material-icon__frame, .item-icon__frame {
        width: 120px;
        height: 120px;
      }
    }
  }

  @media screen and (max-width: $breakpoint-1200) {
    &__timer {
      &-countdown {
        font-size: 26px;
        margin-top: 10px
      }

      &-subtitle {
        font-size: 14px;
      }
    }
  }
}
