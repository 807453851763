.home-banner {
  &__placeholder {
    left: 0;
    right: 0;
    bottom: -85px;
    margin: 0 auto;
    z-index: 1;
    position: absolute;
    max-width: 100%;
  }

  &__modal {
    color: #87682d;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    position: relative;
    top: 60px;

    &-separator {
      margin-bottom: 10px;
    }

    &-text {
      font-size: 18px;
      line-height: 2;
      a {
        text-decoration: underline;
        transition: 0.2s;
        &:hover {
          color: #dba445;
        }
      }
    }
    &-bold {
      font-weight: 700;
      font-size: 25px;

      &--download {
        font-size: 18px;
      }
    }

    &-bg {
      background: url($image-path + "banners/download-bg.png") no-repeat center;
      background-size: contain;
      box-shadow: none;
      height: 500px;
      max-width: 100% !important;
    }

    &-buttons {
      display: flex;
      margin: 30px auto 0;
      width: 70%;
      justify-content: space-evenly;

      a {
        transition: 0.2s;
        &:hover {
          transform: translateY(5px);
        }
      }
      img {
        width: 90%;
      }
    }
  }

  &__lottie {
    position: absolute;
    bottom: 145px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
  }

  &__button {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: fit-content;
    z-index: 1;
    transition: 0.2s;

    &:hover {
      bottom: 45px;
    }

    img {
      width: 250px;
    }
  }

  .progress-bar {
    &__info {
      width: 660px;
      margin-bottom: 40px;
      font-size: 18px;
      justify-content: space-between;

      h4 {
        font-weight: normal;
      }
    }

    &__bar {
      width: 730px;
      height: 12px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -77px;

      div {
        width: 100%;
        box-shadow: 0 3px 6px 0 #565656;
      }
    }
  }

  @media screen and (max-width: $breakpoint-1200) {
    &__placeholder {
      width: 600px;
      bottom: -55px;
    }

    &__button {
      bottom: 30px;

      img {
        width: 150px;
      }

      &:hover {
        bottom: 5%;
      }
    }

    .progress-bar {
      &__info {
        font-size: 14px;
        top: 7px;
        width: 415px;
      }

      &__bar {
        width: 455px;
        height: 8px;
        bottom: -50px;
      }
    }
  }

  @media screen and (max-width: $breakpoint-900) {
    &__lottie {
      display: none;
    }
  }

  @media screen and (max-width: $breakpoint-600) {
    &__modal {
      top: 100px;

      &-bold,
      &-text {
        font-size: 16px;
      }

      &-buttons {
        margin-top: 20px;
        img {
          width: 80%;
        }
      }
    }
    &__placeholder {
      bottom: -40px;
    }

    &__button {
      // bottom: 15px;

      img {
        width: 120px;
      }

      &:hover {
        bottom: 10px;
      }
    }

    .progress-bar {
      &__info {
        display: none;
      }

      &__bar {
        display: block;
        width: 410px;
        height: 5px;
        bottom: -35px;
      }
    }
  }

  @media screen and (max-width: $breakpoint-500) {
    &__modal {
      &-bold,
      &-text {
        font-size: 14px;
      }

      &-bold:nth-child(2) {
        margin-bottom: 5px !important;
      }
    }
  }

  @media screen and (max-width: $breakpoint-414) {
    &__modal {
      top: 150px;

      &-separator {
        margin-bottom: 0;
      }
      &-buttons {
        margin-top: 5px;
      }
    }
    &__button {
      bottom: 20px;
      img {
        width: 100px;
      }
    }

    .progress-bar {
      &__bar {
        display: none;
      }
    }
  }

  @media screen and (max-width: $breakpoint-375) {
    &__modal {
      &-bold,
      &-text {
        font-size: 12px;
      }

      &-buttons {
        width: 100%;
        margin-top: 10px;
        img {
          width: 60%;
        }
      }
    }

    &__placeholder {
      width: 325px;
      bottom: -35px;
    }

    &__button {
      bottom: 10px;

      img {
        width: 80px;
      }
    }

  }
}
