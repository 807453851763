.guild-details {
  background: url($image-path + "guild/background.png") no-repeat fixed;
  background-size: cover;
  padding: 100px 0;

  &__container {
    display: flex;
    align-items: center;
    border: 1px solid #987e5a;
    border-radius: 5px;
    background: linear-gradient(180deg, rgba(66, 52, 4, 0) 0%, #3a2e00 100%);
    height: fit-content;
    margin: 25px auto 0;
    padding: 20px;
  }

  &__popper {
    margin-left: 30px;

    ul {
      background-color: $color-8;
      padding: 0;
      border-radius: 10px;

      li {
        padding: 10px 50px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        &:last-child {
          border-bottom: 0;
        }

        p {
          font-family: $font-base;
        }
      }
    }
  }

  &__input {
    .Mui-focused {
      color: #C0A272 !important;
  
      .MuiInputBase-input {
        color: #C0A272 !important;
      }
  
      .MuiOutlinedInput-notchedOutline,
      .MuiInputLabel-formControl {
        border-color: #C0A272 !important;
      }
  
      .MuiSelect-icon {
        color: #C0A272;
      }
    }
  }

  &__table {
    .request-btn {
      display: flex;
      width: fit-content;
      margin: 20px auto;
      margin-right: 0;
      column-gap: 20px;
      row-gap: 10px;

      .btn--red {
        width: 115px;
      }
    }
  }
  &__flex {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    margin-left: 20px;
  }

  &__content {
    width: 70%;

    .flex {
      column-gap: 50px;
      flex-wrap: wrap;
    }
    &--name {
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 150%;
    }

    &--level,
    &--strength,
    &--members,
    span {
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
      text-transform: uppercase;
      margin-top: 5px;
    }
    span {
      img {
        width: 15px;
        position: relative;
        top: 2px;
      }
    }

    &--desc {
      line-height: 1.6;
      margin-top: 10px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .btn {
      background-color: $color-8;
      border-color: $color-8;
      color: white;

      &:hover {
        background-color: $color-9;
      }

      &--red {
        &:hover {
          background-color: #e86f6f;
        }
      }
      span {
        background-color: #e86f6f;
        color: white;
        font-size: 14px;
        padding: 1px 5px;
        margin-left: 5px;
        border-radius: 4px;
      }
    }
  }

  @media screen and (max-width: $breakpoint-1000) {
    &__flex {
      flex-direction: column;
      align-items: flex-start;
      margin-left: 50px;
    }

    &__content {
      width: 90%;
    }

    &__buttons {
      flex-direction: row;
      column-gap: 20px;
      margin-top: 20px;
    }
  }

  @media screen and (max-width: $breakpoint-700) {
    &__container {
      flex-direction: column;
    }

    &__flex {
      margin: 50px 0 0;
      align-items: center;
    }
  }

  @media screen and (max-width: $breakpoint-600) {
    &__table {
      .request-btn {
        flex-direction: column;
      }
    }

    &__buttons {
      flex-direction: column;
    }
  }
}
