.token {
  &__icon {
    max-width: 70px;
    text-align: center;
  }

  &__logo {
    width: 65px;
    height: 65px;
  }
}
