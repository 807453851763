.gacha {
  position: relative;
  margin: -100px 0 -100px;
  background: url($image-path + 'gacha/gacha-bg.png') no-repeat center;
  background-size: cover;
  overflow: hidden;
  color: #d4c7b5;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: $yellow-2;
    z-index: 1;
  }

  &__closed {
    font-size: 60px;
    position: absolute;
    left: 43%;
    bottom: 40%;

    font-family: $font-sub;
  }

  &__carousel {
    .CarouselItem {
      width: 80%;
      margin: 0 auto;
    }
  }

  &__frame {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 50%;
    margin: 0 auto;
    transform: translateY(-50%);
    height: calc(100% - 40px);
    width: 100%;
    max-width: calc(100% - 20px);
  }

  &__items-left {
    position: absolute;
    margin: 0 auto;
    bottom: 250px;
    left: 0;
    right: 0;
    max-width: 750px;
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
  }

  &__container {
    min-height: 800px;
    position: relative;
    z-index: 2;
  }

  &__banner {
    max-width: 1000px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      cursor: pointer;
      transition: all .3s;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__select {
    padding-bottom: 300px;

    #player-container {
      margin-bottom: -35px;
    }

    &-roll {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-text {
      padding: 5px 15px;
      position: relative;
      font-family: $font-sub;
      font-size: 32px;
      margin-right: 12px;

      &:before,
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        left: 0;
        position: absolute;
        background-color: $yellow-2;
      }

      &:before {
        top: 0;
      }

      &:after {
        bottom: 0;
      }
    }

    &-info {
      max-width: 200px;
      line-height: 22px;
      font-weight: 500;
      text-align: center;
      margin: 15px auto 35px;
    }

    &-btn {
      width: 28px;
      cursor: pointer;
      position: relative;
      top: -8px;
      transition: all .2s;

      &:last-child {
        position: relative;
        left: 2px;
        top: 6px;
        transform: rotate(180deg);
      }

      &:hover {
        opacity: 0.8;
      }

      &:active {
        top: -3px;

        &:last-child {
          top: 2px;
        }
      }
    }
  }

  &-action {
    margin-top: 35px;
  }

  &__btn {
    width: 160px;
    position: relative;
    z-index: 4;
    cursor: pointer;
    margin-right: 35px;
    transition: all .3s;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      transform: translateY(2px);
    }

    &:last-child {
      margin-right: 0;
    }

    &--small {
      width: 70px;
      margin: 0;
    }
  }

  &__confirm {
    padding-top: 150px;

    .gacha__item {
      text-align: center;

      .item-icon:nth-child(5n) {
        margin-right: 0;
      }
    }

    &-inventory {
      max-width: 420px;
      margin: 0 auto 40px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  &__storage {
    width: 80%;
    max-width: 760px;
    height: 240px;
    margin: 0 auto;
    padding: 20px 20px 10px 20px;
    border: 3px solid $color-8;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #161815;
    position: absolute;
    bottom: 3px;
    left: 0;
    right: 0;
    z-index: 5;

    &-container {
      width: 100%;
      height: 100%;
      overflow: auto;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
    }

    &:before {
      width: 224px;
      height: 214px;
      left: -224px;
      background-image: url($image-path + 'gacha/hero-statue-left.png');
    }

    &:after {
      width: 241px;
      height: 209px;
      right: -244px;
      background-image: url($image-path + 'gacha/hero-statue-right.png');
    }
  }

  @media screen and (max-width: $breakpoint-900) {
    &__items-left {
      max-width: 80%;
    }

    &__closed {
      font-size: 50px;
    }
  }

  @media screen and (max-width: $breakpoint-600) {
    padding-top: 60px;

    &__closed {
      font-size: 40px;
    }

    &__carousel {
      .MuiIconButton-label {
        width: 20px;
        margin-top: 5px;
      }

      .MuiIconButton-root {
        padding: 0;
      }
    }
    &__container {
      min-height: 500px;
    }

    &__select {
      padding-bottom: 300px;

      #player-container {
        margin-bottom: 0;
      }
    }

    &__btn {
      width: 120px;
      margin-right: 20px;

      &--small {
        width: 60px;
        margin-right: 0;
      }
    }

    &__select-btn {
      width: 22px;
    }

    &__confirm {
      padding: 50px 20px 280px;
    }
  }

  @media screen and (max-width: $breakpoint-375) {
    padding-top: 80px;

    &__closed {
      font-size: 20px;
    }

    &__select {
      padding-bottom: 310px;

      &-text {
        font-size: 24px;
      }
    }

    &__btn {
      width: 100px;

      &--small {
        width: 50px;
      }
    }

    &__confirm {
      .item-icon {
        margin-right: 19px !important;

        &:nth-child(3n) {
          margin-right: 0 !important;
        }
      }
    }
  }
}