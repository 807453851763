.knight-staking {
  display: flex;
  justify-content: space-between;

  &__left {
    width: 270px;
    position: relative;

    .knights__item-id {
      top: 0;
      left: 0;
    }

    .knights__item-level {
      top: 22px;
      left: -10px;
    }
  }

  &__right {
    width: calc(100% - 290px);
  }

  &__info {
    max-width: 420px;
    line-height: 17px;
  }

  &__loading {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    bottom: 25px;
  }

  &__highlight-text {
    color: $color-9;
    margin-left: 7px;
    font-size: 12px;
  }

  &__warning-text {
    color: $yellow;
    font-size: 12px;
    font-weight: 500;
    font-style: italic;
  }

  .knights__item {
    display: block;
    padding: 0;
    text-align: center;

    &-data {
      justify-content: center;
    }

    .knights__item-footer .flex-center-center {
      justify-content: center;
    }
  }

  @media screen and (max-width: $breakpoint-1200) {
    display: block;

    &__left,
    &__right {
      width: 100%;
    }

    .knights__item-content {
      right: 0;
    }
  }
}