.banner {
  width: 100%;
  height: 800px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 100px;
  font-family: $font-sub;

  &__lion {
    &--left {
      position: absolute;
      left: 2%;
      bottom: -240px;
      z-index: 3;
    }
    &--right {
      position: absolute;
      right: 2%;
      bottom: -240px;
      z-index: 3;
    }
  }

  &__instructions {
    background-position: bottom;
    height: 1080px;
    margin-bottom: 0 !important;
    position: relative;

    &-image {
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      position: relative;

      &--orc {
        left: 50px;
        top: 100px;
        position: absolute;

        img {
          width: 250px;
        }
      }

      &--human {
        right: 0;
        bottom: 0;
        position: absolute;

        img {
          width: 450px;
        }
      }

      &--logo {
        top: 25px;
        right: 0;
        left: 0;
        position: absolute;
        width: fit-content;
        margin: 0 auto;
        img {
          width: 150px;
        }
      }
    }

    &-content {
      color: black;
      position: absolute;
      top: 150px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 50%;
      font-family: $font-base;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      text-align: justify;

      h1 {
        font-size: 40px;
        font-family: $font-sub;
        letter-spacing: 2px;
        text-align: center;
      }
      div {
        margin-bottom: 10px;
      }
    }

    &-format {
      left: 0;
      right: 0;
      bottom: -150px;
      z-index: 1;
      position: absolute;
      display: inline-block;
      text-align: center;
      width: 60%;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }
  }

  &__bar {
    left: 0;
    width: 100%;
    bottom: 0;
    z-index: 1;
    position: absolute;
  }

  &__home {
    background-position: bottom;
    margin-bottom: 0;
    position: relative;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.3);
    }

    &-image {
      width: 75%;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      position: relative;
      max-width: 100%;

      &--orc {
        left: 5%;
        bottom: 15%;
        position: absolute;

        img {
          width: 20vw;
          min-width: 350px;
        }
      }

      &--human {
        right: 0;
        bottom: 0;
        position: absolute;

        img {
          width: 35vw;
          min-width: 600px;
        }
      }

      &--logo {
        top: 11%;
        right: 15%;
        position: absolute;

        img {
          width: 20vw;
          min-width: 300px;
        }
      }
    }
  }

  &__content {
    left: 0;
    right: 0;
    bottom: -70px;
    z-index: 1;
    position: absolute;
    display: inline-block;
    text-align: center;
    width: 100%;
    max-width: 740px;
    margin: 0 auto;
    padding: 0 20px;
  }

  &__titles {
    position: relative;
    top: 151px;
    text-transform: uppercase;
    font-family: $font-sub;
  }

  &__title {
    font-size: 48px;

    &-event {
      font-size: 24px;
      line-height: 5px;
    }
  }

  &__subtitle {
    font-size: 40px;
    color: $yellow;
    text-shadow: 0 1px 4px #131212;
  }

  &__placeholder {
    max-width: 100%;
  }

  &__land {
    &-phase {
      font-size: 3em;
      font-family: $font-sub;
      color: $yellow;
      bottom: 20%;
      left: 0;
      right: 0;
      margin: 0 auto;
      position: absolute;
      letter-spacing: 2px;
      width: fit-content;
    }
  }
  @media screen and (max-width: $breakpoint-1200) {
    max-height: 500px;
    background-position: center 0;

    &__lion {
      &--left,
      &--right {
        width: 150px;
        bottom: -200px;
      }
    }

    &__instructions {
      max-height: 100%;
      background-position: -300px;

      &-content {
        font-size: 14px;
        h1 {
          font-size: 30px;
        }
      }
      &-image {
        &--orc {
          img {
            width: 150px;
          }
        }
        &--human {
          img {
            width: 400px;
          }
        }
      }
    }

    &__home {
      &-image {
        width: 80%;

        &--orc {
          img {
            min-width: 250px;
          }
        }

        &--human {
          img {
            min-width: 400px;
          }
        }

        &--logo {
          img {
            min-width: 200px;
          }
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-1000) {
    &__instructions {
      &-format {
        width: 80%;
        bottom: -100px;
      }
    }
  }

  @media screen and (max-width: $breakpoint-900) {
    &__instructions {
      &-format {
        bottom: -100px;
      }
    }

    &__lion {
      &--left,
      &--right {
        display: none;
      }
    }
  }

  @media screen and (max-width: $breakpoint-800) {
    &__instructions {
      &-image {
        &--orc {
          left: 10px;
          top: 40%;
          img {
            width: 120px;
          }
        }
        &--human {
          bottom: 30px;
          img {
            width: 300px;
          }
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-700) {
    margin-bottom: 100px;
    max-height: 450px;

    &__instructions {
      max-height: 100%;

      &-image {
        &--orc {
          display: none;
        }

        &--human {
          display: none;
        }
      }
      &-content {
        width: 80%;
      }
      &-format {
        display: none;
      }
    }

    &__titles {
      top: 125px;
    }

    &__home {
      margin-bottom: 0;

      &-image {
        width: 100%;

        &--orc {
          img {
            min-width: 200px;
          }
        }

        &--human {
          img {
            min-width: 300px;
          }
        }

        &--logo {
          img {
            min-width: 150px;
          }
        }
      }
    }

    &__title {
      font-size: 35px;

      &-event {
        font-size: 18px;
      }
    }

    &__subtitle {
      font-size: 30px;
    }
  }

  @media screen and (max-width: $breakpoint-600) {
    &__titles {
      top: 100px;
    }

    &__title {
      font-size: 28px;

      &-event {
        font-size: 14px;
      }
    }

    &__subtitle {
      font-size: 25px;
    }
  }

  @media screen and (max-width: $breakpoint-500) {
    &__instructions {
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.3);
      }
      background-position: start;
      &-content {
        width: 90%;
        color: white;
        div {
          text-align: center;
          margin-bottom: 15px;
        }
      }
    }
    &__titles {
      top: 83px;
    }

    &__title {
      font-size: 23px;

      &-event {
        font-size: 10px;
      }
    }

    &__subtitle {
      font-size: 20px;
    }
  }

  @media screen and (max-width: $breakpoint-414) {
    &__titles {
      top: 70px;
    }

    &__title {
      font-size: 18px;
    }

    &__subtitle {
      font-size: 16px;
    }
  }

  @media screen and (max-width: $breakpoint-375) {
    &__home {
      &-image {
        &--orc {
          img {
            min-width: 150px;
          }
        }

        &--human {
          img {
            min-width: 200px;
          }
        }

        &--logo {
          top: 15%;
          img {
            min-width: 170px;
          }
        }
      }
    }

    &__titles {
      top: 66px;
    }

    &__title {
      font-size: 18px;
    }

    &__subtitle {
      font-size: 16px;
    }

    &__placeholder {
      bottom: 40px;
    }
  }

  @media screen and (max-width: $breakpoint-320) {
    &__titles {
      top: 60px;
    }
  }
}
