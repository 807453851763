.modal {
  max-width: 90%;
  max-height: 85%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 88;
  pointer-events: none;

  &-opened {
    overflow: hidden;
  }

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    z-index: 88;
    overflow: auto;
    transition: all 0.3s;

    &--active {
      visibility: visible;
      opacity: 1;
    }

    &--layer-0 {
      z-index: 998;
    }

    &--layer-2 {
      z-index: 1000;
    }

    &--layer-3 {
      z-index: 1001;
    }
  }

  &__title {
    font-size: 18px;
    margin-bottom: 25px;
  }

  &__close-btn {
    font-size: 25px;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    border-radius: 2px;
    color: $text;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: $bg;
    }
  }

  &__wrapper {
    padding-bottom: 30px;
  }

  &__content {
    color: $text;
    width: 470px;
    max-width: 95%;
    background-color: $color-8;
    padding: 50px 30px 30px;
    margin: 10px auto 50px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: scale(0.9);
    border-radius: 8px;
    box-shadow: 0 12px 64px 0 rgba(19, 23, 32, 0.81);
    transition: transform 0.2s;

    &.small {
      width: 380px;
      padding: 35px 30px 25px;

      .basic-modal__panel {
        padding-top: 20px;
        margin-top: 20px;
      }
    }

    &.medium {
      width: 550px;
    }

    &.level-up {
      width: 750px;
    }

    &.large {
      width: 1000px;
    }

    &.fit-content {
      width: fit-content;
    }
    &.super-large {
      width: 1200px;
    }
  }

  &--active {
    pointer-events: visible;

    .modal__content {
      transform: scale(1);
      opacity: 1;
      visibility: visible;
      pointer-events: visible;
    }
  }

  @media screen and (max-width: $breakpoint-414) {
    &__close-btn {
      right: 14px;
    }

    &__content {
      padding: 35px 16px;
    }
  }
}

.basic-modal {
  text-align: left;
  font-weight: normal;
  max-width: 100%;

  &__title {
    font-size: 18px;
    margin-bottom: 30px;
    font-weight: 500;
  }

  &__content {
    line-height: 1.6;
  }

  &__panel {
    border-top: 1px dashed grey;
    text-align: center;
    padding: 30px 0 0;
    margin-top: 25px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 20px;

    &--extra {
      justify-content: space-between;
      border-top: 1px dashed grey;
      text-align: center;
      padding: 30px 0 0;
      margin-top: 25px;
      display: flex;
      flex-wrap: wrap;
      row-gap: 20px;
      flex-direction: column;
      align-items: center;

      .basic-modal__panel-container {
        width: 100%;

        .btn {
          width: 45%;
          &:nth-child(2) {
            margin-left: 10% !important;
          }
        }
      }
      .btn {
        width: 100%;
      }
    }
    .btn {
      width: 45%;

      &:nth-child(2) {
        margin-left: 10% !important;
      }
    }
  }

  &__text-center {
    max-width: 260px;
    margin: 0 auto;
    text-align: center;
  }

  &.title-center .basic-modal__title {
    text-align: center;
    margin-bottom: 20px;
  }

  &--normal-lh {
    .basic-modal__content {
      line-height: inherit;
    }
  }

  @media screen and (max-width: $breakpoint-414) {
    &__panel {
      .btn {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
}

.loading-modal {
  width: 210px;
  padding: 0 0 30px;
}
