.knight-skills {
  .inventory {
    padding: 25px 20px 0;
    margin-top: 15px;
  }

  &__available {
    color: $color-9;

    &--true {
      color: $color-9;

      position: relative;
      bottom: 5px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    &--false {
      color: $red;
      position: relative;
      bottom: 5px;
      font-weight: bold;
      margin-bottom: 15px;
    }
  }

  &__title {
    font-family: $font-sub;
    font-size: 28px;
    position: relative;
    top: 5px;
  }

  &__btn {
    position: relative;
    top: 4px;

    .hidden {
      background-color: $color-9;
      color: white;
    }
  }

  &__tree {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 25px;

    h2 {
      font-size: 40px;
      font-weight: normal;
    }

    &--closed {
      display: none;
      [data-testid="visibility-container"] {
        visibility: hidden;
        height: 0;
        transition: transform 0.15s ease-out, opacity 0.15s ease-out, max-height 0.15s ease-out,
          visibility 0.15s 0.15s ease-out;
        transform: scaleY(0);

        transform-origin: top;
      }
    }

    div {
      &::before {
        border-bottom: 0;
        border-right: 0;
      }
      &::after {
        border-top: 0;
        border-left: 0;
      }
    }
  }
}

.tippy-popper {
  h1 {
    font-weight: normal;
  }

  .tippy-content {
    > div {
      > div:nth-child(2) {
        color: $color-mythical;
      }
    }
  }
}

