@mixin generateSpacingClasses($prefix, $property) {
  $sizes: [5px, 10px, 15px, 20px, 25px, 30px, 35px, 40px, 45px, 50px];

  @for $i from 1 through length($sizes) {
    $size: nth($sizes, $i);

    .#{$prefix}-#{$i} {
      #{$property}: $size !important;
    }
  }
}

@mixin generateFontSizeClasses($prefix, $property) {
  $sizes: [10px, 12px, 14px, 16px, 20px];

  @for $i from 1 through length($sizes) {
    $size: nth($sizes, $i);

    .#{$prefix}-#{$i} {
      #{$property}: $size !important;
    }
  }
}

@mixin generateFontWeightClasses($prefix, $property) {
  $sizes: [300, 400, 500, 600, 700];

  @for $i from 1 through length($sizes) {
    $size: nth($sizes, $i);

    .#{$prefix}-#{$i} {
      #{$property}: $size !important;
    }
  }
}

@mixin generatePositionClasses($prefix, $property) {
  $sizes: [1px, 2px, 3px, 4px, 5px, 6px, 7px, 8px, 9px, 10px];

  @for $i from 1 through length($sizes) {
    $size: nth($sizes, $i);

    .#{$prefix}-#{$i} {
      position: relative;
      #{$property}: $size !important;
    }
  }
}
