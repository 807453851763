.equipment {
  padding-top: 150px;

  &__header {
    display: flex;
    justify-content: space-between;

    &-buttons {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 25px;

      .btn {
        font-size: 10px;
        padding: 10px 15px
      }
    }
  }

  &__balance {
    margin: 15px 0;

    .MuiFormLabel-root.Mui-disabled {
      color: $color-9;
      font-size: 12px;
      left: 5px;
    }

    .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
      border-color: $color-9;
      padding: 0 6px;
    }

    .MuiInputBase-input.Mui-disabled {
      text-align: center;
      font-size: 24px;
      color: $color-9;
      width: 100px;
    }

    .MuiOutlinedInput-input {
      padding: 10px 0;
    }

    &-title {
      font-family: $font-base;
      font-weight: 600;
      font-size: 12px;
    }

    &-value {
      font-family: $font-sub;
      font-size: 36px;
      margin-top: 2px;
    }
  }

  &__title {
    font-family: $font-sub;
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 15px;
  }

  &__id {
    margin: 35px 0 10px;
  }

  &__name {
    font-size: 64px;
    font-family: $font-sub;
    margin-bottom: 25px;
  }

  &__info {
    border: 1px solid $color-7;
    border-radius: 5px;
    padding: 20px 45px 25px;
    display: flex;
    justify-content: space-between;
    text-align: center;

    &-title {
      font-weight: 500;
      margin-bottom: 15px;
      color: $gray;
      font-size: 16px;
    }

    &-value {
      color: $color-9;
    }

    &-tag {
      padding: 3px 10px 5px;
      width: 100px;
      border-radius: 3px;
      text-align: center;
      display: inline-block;
      text-transform: capitalize;

      &--common {
        background-color: $color-common;
      }

      &--uncommon {
        background-color: $color-uncommon;
      }

      &--rare {
        background-color: $color-rare;
      }

      &--epic {
        background-color: $color-epic;
      }

      &--legendary {
        background-color: $color-legendary;
      }

      &--mythical {
        background-color: $color-mythical;
      }

      &--small {
        width: initial;
        padding: 4px 15px;
        font-size: 14px;
      }
    }
  }

  &__block {
    margin-top: 20px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__column {
    width: 48%;
  }

  &__table {
    .MuiTableContainer-root {
      max-height: 340px;
    }
  }

  .toggle {
    width: 75px;
    position: relative;
    bottom: 7px;
  }

  &__dismantle {
    gap: 25px;

    &-materials {
      position: relative;
      top: -15px;
    }

    &-modal {
      .basic-modal__title {
        margin-bottom: 0;
      }
    }

    &-amount {
      position: relative;
      top: -5px;
    }

    .material-icon__frame {
      width: 120px;
      height: 120px;
    }

    .material-icon__preview {
      top: 46%;
    }

    &-points {
      .progress-bar__info {
        margin: 5px auto;
      }
    }

    .item-icon__gem {
      &--0 {
        bottom: 11.4%;
      }
    }
  }
  
  @media screen and (max-width: $breakpoint-959) {
    &__header,
    &__container {
      display: block;
    }

    &__header {
      &-data {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0 15px;
      }

      &-buttons,
      .detail-price {
        margin: 0;
      }

      .detail-price {
        &__price {
          font-size: 40px;
        }

        &__logo {
          width: 45px;
          height: 45px;
        }
      }
    }

    &__column {
      width: 100%;

      .cm-table {
        margin-top: 30px;
      }
    }

    .item-icon__frame {
      margin: 0 auto;
    }
  }

  @media screen and (max-width: $breakpoint-600) {
    &__name {
      font-size: 40px;
      word-break: break-word;
    }

    &__header {
      &-data {
        flex-direction: column-reverse;
        align-items: flex-end;
        margin-bottom: 15px;
      }

      &-buttons {
        margin-top: 20px;
      }

      .detail-price {
        &__price {
          font-size: 30px;
        }

        &__logo {
          width: 35px;
          height: 35px;
        }
      }
    }

    &__info {
      padding: 20px 10px;
      font-size: 12px;

      &-tag {
        width: initial;
      }
    }

    .item-icon__frame {
      width: 250px;
      height: 250px;
    }

    .cm-table__header {
      display: block;

      .flex-center-start {
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin-bottom: 10px;
      }

      .btn {
        margin-right: 10px;
      }
    }
  }
}
