.lands {
  &__item {
    justify-content: center;

    &--disconnected {
      cursor: default;
      transition: none;

      &:hover {
        background-color: $bg;
      }
    }

    &--full {
      width: 100% !important;
      border: none !important;
      pointer-events: none;
      animation: none;
      padding: 0 !important;

      .lands__item-data {
        top: 0;
      }

      .lands__item-images {
        right: 15px;
      }

      .lands__item-content {
        right: 18px;
        position: relative;
      }
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    &-images {
      position: relative;
      z-index: 1;
      margin-top: 20px;
    }

    &-reward {
      position: absolute;
      top: 45px;
      left: 20px;
      border-radius: 3px;
      font-size: 12px;
      font-weight: 500;
      z-index: 9;
      color: #ababab;

      &--token {
        position: relative;
        width: 12px;
        top: 1px;
      }
    }
    &-character {
      width: 100%;
      max-width: 180px;
      position: relative;

      &--placeholder {
        filter: brightness(15%);
      }
    }

    &-highlight {
      width: 110px;
      position: absolute;
      top: 40px;
      left: 0;
      right: 0;
      margin: 0 auto;
      opacity: 0.6;
    }

    &-level {
      color: $gray;
      position: absolute;
      top: 42px;
      left: 11px;
      padding: 3px 10px;
      border-radius: 3px;
      font-size: 10px;
      font-weight: 500;
      z-index: 9;
    }

    &-id {
      position: absolute;
      top: 20px;
      left: 20px;
      padding: 3px 10px;
      border-radius: 3px;
      font-size: 11px;
      font-weight: 500;
      z-index: 9;

      &.glacial {
        background-color: $color-10;
      }

      &.inferno {
        background-color: $color-15;
      }

      &.aquatic {
        background-color: $color-16;
      }

      &.golem {
        background-color: $color-12;
      }
    }

    &-data {
      position: absolute;
      right: 4px;
      top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &-element {
      width: 30px;
    }

    &-type {
      font-family: $font-sub;
      font-size: 50px;
      color: $gray;
      text-transform: uppercase;
      font-weight: 500;
      margin-top: 30px;
      writing-mode: vertical-rl;
      letter-spacing: 1px;
    }

    &-footer {
      text-align: center;
      margin-top: 10px;
    }

    &-token {
      width: 30px;
      margin-right: 7px;

      &--fara {
        width: 30px;
        height: 30px;
        margin-right: 7px;
      }
    }

    &-price {
      font-family: $font-sub;
      font-size: 30px;
      text-align: left;
    }

    &-value {
      font-family: $font-base;
      font-size: 14px;
      color: $gray;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &-tier {
      display: flex;
      gap: 5px;
      img {
        width: 18px;
      }
    }
  }

  @media screen and (max-width: $breakpoint-1000) {
    &__item {
      &-data {
        position: initial;
        align-items: flex-start;
      }
      &-images {
        margin-top: 0;
      }
      &-type {
        writing-mode: horizontal-tb;
        margin-top: 10px;
        font-size: 24px;
      }
    }
  }
}
