.guild-list {
  background: url($image-path + "guild/background.png") no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding-top: 150px;
  padding-bottom: 100px;

  &__content {
    border: 1px solid #987E5A;
    background: linear-gradient(180deg, rgba(66, 52, 4, 0) 0%, #3A2E00 100%);
    margin-bottom: 50px;
    height: 115px;

    &-container {
      margin-left: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    &-info {
      line-height: 150%;

      &--title {
        font-size: 16px;
        font-weight: 800;
      }

      &--level {
        font-size: 12px;
        font-weight: lighter;
        color: #C4C4C4;
      }

      &--strength, &--members, span {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
      }

      span {
        img {
          width: 15px;
          position: relative;
          top: 2px;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-900) {
    padding-top: 100px;
  }

  @media screen and (max-width: $breakpoint-600) {
    &__content {
      height: auto;

      &-info {
        &--title {
          font-size: 14px;
        }

        &--strength, &--members, span {
          font-size: 12px;
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      &-container {
        flex-direction: column;
        row-gap: 15px;
        align-items: flex-start;
        margin: 10px 0 10px 100px;
      }
    }
  }

}