@include generateSpacingClasses(ml, margin-left);
@include generateSpacingClasses(mr, margin-right);
@include generateSpacingClasses(mt, margin-top);
@include generateSpacingClasses(mb, margin-bottom);

@include generateSpacingClasses(pl, padding-left);
@include generateSpacingClasses(pr, padding-right);
@include generateSpacingClasses(pt, padding-top);
@include generateSpacingClasses(pb, padding-bottom);

@include generateFontSizeClasses(fs, font-size);
@include generateFontWeightClasses(fw, font-weight);

@include generatePositionClasses(top, top);
@include generatePositionClasses(bot, bottom);

@font-face {
  font-family: "American Captain";
  src: url($font-path + "american-captain/american-captain.ttf") format("truetype"),
    url($font-path + "american-captain/american-captain.otf") format("opentype");
}

* {
  box-sizing: border-box;
}

body {
  font-family: $font-base;
  color: $text;
  background-color: $bg;
  margin: 0;
  font-size: 14px;
}

input {
  font-family: $font-base;
  outline: none;
  box-shadow: none;
  border: none;
}

a {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

b {
  font-weight: 600;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.mt-100 {
  margin-top: 100px;
}

.no-margin {
  margin: 0 !important;
}

.center-margin {
  margin: 0 auto;
}

.normal-line-height {
  line-height: 1.4;
}

.line-height-1 {
  line-height: 1 !important;
}

.container {
  max-width: $container-width;
  padding: 0 16px;
  margin: 0 auto;
}

.hidden {
  visibility: hidden;
}

.modal-active,
.menu-active {
  overflow: hidden;
  margin-right: 8px;

  @media screen and (max-width: $breakpoint-900) {
    margin-right: 0;
  }
}

.text-9 {
  color: $color-9 !important;
}

.text-17 {
  color: $color-17 !important;
}

.text-orange {
  color: $orange !important;
}

.text-yellow {
  color: $yellow !important;
}

.text-gray {
  color: $gray !important;
}

.text-white {
  color: white !important;
}

.text-red {
  color: $red !important;
}

.capitalize {
  display: inline-block;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}

.bold {
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.relative {
  position: relative;
}

.disabled {
  opacity: 0.4 !important;
  pointer-events: none !important;
  cursor: initial !important;
}

.soft-disabled {
  opacity: 0.8;
  pointer-events: none;
  cursor: initial;
}

.unclickable {
  pointer-events: none;
  cursor: initial;
}

.low-opacity {
  opacity: 0.4;
}

.z-index-2 {
  position: relative;
  z-index: 2;
}

.border-top {
  border-top: 1px dashed $gray;
}

.slide-up {
  animation: slide-up 0.3s forwards;
}

.slide-up-slow {
  animation: slide-up-slow 0.4s forwards;
}

.fade-in {
  animation: fade-in 0.3s forwards;
}

.fade-out {
  animation: fade-out 0.3s forwards;
}

.inline-block {
  display: inline-block;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}

.flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-start-between {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.flex-center-start {
  display: flex;
  align-items: center;
}

.flex-start-center {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.flex-center-end {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.flex-end-none {
  display: flex;
  align-items: flex-end;
}

.flex-start-none {
  display: flex;
  align-items: flex-start;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-column-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-column-center-none {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-2-column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  div {
    width: 48%;
    min-width: 80px;
  }
}

.filter-gray {
  filter: grayscale(1);
}

.token {
  width: 24px;
  height: 24px;
  position: relative;

  &--small {
    width: 20px;
    height: 20px;
  }

  &--fara {
    width: 30px;
    height: 30px
  }

  &--black-market {
    width: 40px;
    height: 30px;
  }
}

.icon {
  width: 30px;

  &--small {
    width: 25px;
  }
}

.info-icon {
  margin-left: 2px;
  position: relative;
  top: 3px;
  cursor: pointer;
}

.error-text {
  font-size: 12px;
  font-weight: 500;
  color: $red;
  margin: 5px 0;
  animation: slide-up 0.3s forwards;

  &--no-animation {
    animation: none;
  }
}

.success-text {
  font-size: 12px;
  font-weight: 500;
  color: $color-9;
  margin: 5px 0;
  animation: slide-up 0.3s forwards;

  &--no-animation {
    animation: none;
  }
}

.warning-text {
  font-size: 12px;
  font-weight: 500;
  color: $yellow;
  margin: 5px 0;
  animation: slide-up 0.3s forwards;

  &--no-animation {
    animation: none;
  }
}

.link-text {
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.3s;

  &--color {
    color: $color-9;
  }

  &:hover {
    color: $color-9 !important;
  }
}

.small-text {
  font-size: 12px;
  font-weight: 500;
  margin: 5px 0;

  &--warning {
    color: $yellow;

    div {
      margin-bottom: 2px;
    }
  }

  &--error {
    color: $red;

    div {
      margin-bottom: 2px;
    }
  }
}

.rarity-common {
  color: $color-common;
  border-color: $color-common;
}

.rarity-uncommon {
  color: $color-uncommon;
  border-color: $color-uncommon;
}

.rarity-rare {
  color: $color-rare;
  border-color: $color-rare;
}

.rarity-epic {
  color: $color-epic;
  border-color: $color-epic;
}

.rarity-legendary {
  color: $color-legendary;
  border-color: $color-legendary;
}

.rarity-mythical {
  color: $color-mythical;
  border-color: $color-mythical;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.separator {
  width: 600px;
  max-width: 100%;
}

.spinning {
  animation: spin 2s infinite;
}

.input {
  .MuiInputLabel-formControl {
    color: $gray;
    font-size: 14px;
    transform: translate(17px, -6px) scale(0.7) !important;
  }

  .MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline,
  .MuiInputBase-formControl:hover .MuiInputLabel-formControl {
    border-color: $gray;
  }

  .MuiInputBase-input {
    padding: 8px 10px 13px;
    font-size: 13px;
    color: $gray;
    transition: all 0.3s;
  }

  input {
    padding: 10px 10px 11px !important;
  }

  option {
    color: white !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $gray;
  }

  .MuiSelect-icon {
    color: $gray;
  }

  .Mui-focused {
    color: $color-9 !important;

    .MuiInputBase-input {
      color: $color-9 !important;
    }

    .MuiOutlinedInput-notchedOutline,
    .MuiInputLabel-formControl {
      border-color: $color-9 !important;
    }

    .MuiSelect-icon {
      color: $color-9;
    }
  }

  &--select {
    legend {
      width: 67px;
    }
  }
}

.MuiCheckbox-root {
  .MuiSvgIcon-root {
    color: $gray;
  }

  &.Mui-checked .MuiSvgIcon-root {
    color: $color-9;
  }
}

.MuiSlider-root {
  color: $color-17;

  .MuiSlider-track {
    color: $color-17;
  }

  .MuiSlider-mark {
    color: $color-17;
  }

  .MuiSlider-thumb {
    color: $color-17;
  }

  .MuiSlider-rail {
    color: $gray;
  }
}

.MuiPaginationItem-root {
  padding: 1px 7px 0 6px !important;
}

.autocomplete-paper {
  background-color: $color-8;
  border: 1px solid $color-6;
}

.MuiAutocomplete-listbox {
  position: relative;
  top: -5px;
  margin-top: 20px !important;
  padding-top: 0px !important;

  li {
    width: 95%;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $color-8;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $color-9;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $color-2;
  }
}

.MuiTooltip-tooltip {
  font-family: $font-base !important;
  padding: 8px 10px !important;
  font-size: 12px !important;
  background-color: $color-8 !important;
  max-width: 500px !important;
  word-wrap: normal !important;

  .MuiTooltip-arrow {
    color: $color-8 !important;
  }
}


.new-line {
  white-space: pre-line;
}

.unset-overflow {
  overflow: unset;
}

.overflow-hidden {
  overflow: hidden;
}

.number-input {
  width: 100%;
  .MuiOutlinedInput-root {
    padding: 9px;
  }
}

.full-width {
  width: 100% !important;
}
