.home {
  width: 100%;
  padding-top: 100px;
  background-image:
    url($image-path + "/home/fire-demon.png"),
    url($image-path + "/banners/characters-background.png"),
    url($image-path + "/banners/features-background.png"),
    linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)),
    url($image-path + "/banners/home-background.png");

  background-color: black;

  background-repeat: no-repeat;
  background-attachment: scroll, scroll, scroll, scroll, fixed;
  background-size: auto, auto, auto, 100% 100%, 100% 100%;
  background-position: top -600px center, top 1100px center, top 2550px center, top, center;

  &__demo {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  &__carousel {
    &-preview {
      display: flex;
      justify-content: center;
      gap: 16px;
      margin-top: 100px;
    }

    &-page {
      display: flex;
      justify-content: center;
      margin-top: 10px;

      &--inactive {
        margin: 0 8px;
      }
    }
  }

  &__features {
    display: flex;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;

    &-spacer {
      height: 280px;
    }
  }

  &__soulstone {
    max-width: 850px;
    height: 750px;
    margin: 0 auto;
    position: relative;

    &-title {
      font-family: $font-sub;
      font-size: 64px;
      letter-spacing: 0.018em;
      text-align: center;
      color: $yellow;
      margin-bottom: 30px;
    }

    &-desc {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }

    &-images {
      margin-top: 50px;
      text-align: center;
      display: flex;
      flex-direction: column;

      img {
        max-height: 100px;
        max-width: 100%;
        object-fit: scale-down;
      }
    }
  }

  &__crystal {
    text-align: center;
    max-width: 70%;
    margin: 0 auto 100px;

    &-title {
      font-family: $font-sub;
      font-size: 64px;
      font-weight: 400;
      color: $yellow;
      letter-spacing: 0.018em;
      text-align: center;
    }

    &-usecase {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      img {
        width: 250px;
        margin-bottom: 20px;
        max-height: 85px;
        object-fit: scale-down;
      }
    }
  }

  &__button {
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5);
    opacity: 0.8;
    transition: opacity 0.2s;
    max-width: 100%;

    &:active {
      position: relative;
      top: 1px;
    }

    &:hover {
      opacity: 1;
    }
  }

  @media screen and (max-width: $breakpoint-1000) {
    background-position: top -600px center, top 1100px center, top 3300px center, top, center;

    &__features {
      flex-direction: column;

      &-spacer {
        height: 0;
      }
    }

    &__crystal {
      margin-bottom: 40px;
    }
  }

  @media screen and (max-width: $breakpoint-700) {
    &__soulstone {
      height: 700px;
    }

    &__crystal {
      max-width: 90%;

      &-usecase {
        margin-top: 50px;
      }
    }
  }

  @media screen and (max-width: $breakpoint-600) {
    padding: 25px 10px 10px;
  }
}