.staking-form {
  &__tab {
    margin-top: 30px;

    &-content {
      line-height: 19px;
    }

    &-title,
    &-subtitle {
      font-family: $font-base;
      text-transform: none;
    }

    &-title {
      font-size: 13px;
    }

    &-subtitle {
      display: inline-block;
      font-size: 11px;
      color: $color-9;
      font-weight: 600;

      &--deprecated {
        color: white;
        background-color: $red;
        margin-top: 3px;
        padding: 1px 10px 3px;
        border-radius: 3px;
      }
    }

    .MuiButtonBase-root {
      padding-bottom: 15px;
    }

    .Mui-selected {
      border-radius: 10px 10px 0 0;
      background-color: $bg;
      border: none;
      transition: all .3s;
    }

    .MuiTabs-indicator {
      display: none;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-radius: 0 5px 5px 5px;
    background-color: $bg;
    min-height: 350px;

    .dashed-title span {
      background-color: $bg;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    justify-content: inherit;
    width: 30%;
    line-height: 18px;
  }

  &__countdown {
    height: 37px;
    margin-top: 19px;

    .fs-2 {
      margin-top: 4px;
    }
  }

  &__btn {
    margin-top: 15px;
    width: 100%;

    &.btn--large {
      margin-top: 0;
    }
  }

  .item-icon {
    max-width: 40px;
    margin: 0 10px 10px 0;

    &__image {
      border-width: 2px;
    }
  }

  &__tooltip {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  .MuiFormControl-root.input {
    width: 100%;
  }

  .MuiSelect-select.MuiSelect-select {
    color: white;
  }

  .input .MuiInputLabel-formControl {
    transform: translate(14px, -6px) scale(0.75);
    font-size: 1rem;
  }

  @media screen and (max-width: $breakpoint-700) {
    &__container {
      display: block;
    }

    &__block {
      width: 100%;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__content:first-child {
      margin-bottom: 30px;
    }
  }
}
