.item-upgrade {
  &__container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__content {
    text-align: center;
  }

  &__icon {
    width: 100px;
    cursor: pointer;

    &:hover {
      border-color: $color-14;
    }
  }

  &__tier {
    font-size: 13px;
  }

  &__balance {
    font-size: 12px;
    color: $gray;
  }

  &__arrow {
    text-align: center;
    position: relative;
    top: 33px;
    margin: 0 15px;

    svg {
      color: $color-9;
    }

    div {
      margin-top: -10px;
      font-size: 12px;
      position: relative;
      right: 2px;
    }
  }

  @media screen and (max-width: $breakpoint-600) {
    &__container {
      overflow-x: auto;
      padding: 15px 0 35px;
    }
  }
}