.roadmap {
  padding-top: 32px;
  margin-bottom: 100px;
  position: relative;

  &__header {
    text-align: center;
    position: relative;
    z-index: 2;

    &-title {
      font-family: $font-sub;
      font-weight: 400;
      font-size: 64px;
      letter-spacing: 0.018em;
      line-height: 91.52px;
      color: $yellow;
    }
  }

  &__year {
    text-align: center;
    font-family: $font-sub;
    font-size: 48px;
    line-height: 69px;
    letter-spacing: 0.018em;
    cursor: pointer;
    opacity: 1;

    &--inactive {
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__background {
    &-orc {
      position: absolute;
      top: 0;
      left: 0;
      width: 20%;
      transform: rotate(18.96deg);
    }

    &-human {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 20%;
    }
  }

  &__divider {
    height: 1px;
    background-image: linear-gradient(to right,
        rgba(255, 239, 160, 0),
        rgba(255, 240, 165, 1),
        rgba(255, 239, 160, 0));
  }

  &__collapse {
    max-width: 1300px;
    margin: 0 auto;
  }

  @media screen and (max-width: $breakpoint-700) {
    margin-bottom: 50px;

    &__timeline {
      height: unset !important;
    }

    &__placeholder {
      display: none !important;
    }
  }

  @media screen and (max-width: $breakpoint-500) {
    margin-top: 150px;
  }
}

.timeline {
  position: absolute !important;
  width: 98%;

  &::before {
    padding: 48px 16px;
  }

  &__line {
    background-image: url($image-path + "/home/roadmap/timeline-dot.svg");
    height: 70px;
    width: 190px;
    z-index: 1;
    position: fixed;
    margin-right: 104px;

    &-inactive {
      background-image: url($image-path + "/home/roadmap/timeline-dot-inactive.svg");
      background-repeat: no-repeat;
      margin-right: 108px;

      &--right {
        background-image: url($image-path + "/home/roadmap/timeline-dot-inactive-right.svg");
        background-repeat: no-repeat;
        margin-right: 0px;
        margin-left: 161px;
      }
    }

    &--right {
      background-image: url($image-path + "/home/roadmap/timeline-dot-right.svg");
      margin-right: 0px;
      margin-left: 104px;
    }
  }

  &__connector {
    background-color: #5C503D !important;
    width: 3px !important;
    max-height: 130px;

    &--first {
      margin-top: 25px;
    }

    &--last {
      max-height: 30px;
    }

    &--left {
      flex: inherit !important;
      text-align: left;
      width: 200px;
    }

    &--right {
      flex: inherit !important;
      width: 300px;
    }
  }

  &__content {
    &--left {
      justify-content: flex-start;
      align-items: center;
      text-align: left !important;
      display: flex;
      z-index: 2;
      min-height: 130px;
    }

    &--right {
      justify-content: flex-start;
      align-items: center;
      display: flex;
      z-index: 2;
    }

    &-wrapper {
      &--left {
        margin-left: 50%;
      }

      &--right {
        margin-left: 12px;
      }
    }

    &-time {
      font-size: 36px;
      font-family: $font-sub;
      font-weight: 400;
      line-height: 51.48px;
      letter-spacing: 0.018em;
      color: #ff9800;
      margin-bottom: 10px;
    }

    &-milestone {
      color: white;
      font-size: 14px;
      line-height: 20.02px;
      margin-bottom: 8px;
      letter-spacing: 0.018em;

      span {
        visibility: hidden;
      }

      &--finished {
        span {
          visibility: visible;
          color: #ff9800;
          position: relative;
          top: 5px;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-1000) {
    &__content {
      &-wrapper {
        &--left {
          margin-left: 15%;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-700) {
    position: unset !important;
    min-height: unset !important;

    &::before {
      display: none;
    }

    &__connector {
      max-height: 0;

      &--left,
      &--right {
        width: unset;
      }

      &--first,
      &--last {
        max-height: 0;
      }
    }

    &__content {

      &--left,
      &--right {
        justify-content: center;
      }

      &-wrapper {

        &--left,
        &--right {
          margin-left: 0;
          width: 90%;
          text-align: center;
        }
      }
    }

    &__line {
      display: none;
    }
  }
}