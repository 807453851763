.guild-creation {
  display: flex;

  &__stepper {
    .MuiPaper-root {
      background-color: $color-8;
      margin-bottom: 50px;
    }

    .MuiStepper-root {
      padding: 10px;

      .MuiStepIcon-active,
      .MuiStepIcon-completed {
        color: $color-17;
      }
      .MuiStepIcon-text {
        fill: black;
      }
    }
  }

  &__container {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__image {
    border: 1px solid #ababab;
    border-radius: 50%;
    height: 110px;
    width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 50%;
      border-radius: 50%;
      object-fit: cover;
    }

    &--selected {
      width: 100% !important;
      height: 100%;
    }
  }

  &__text-field {
    height: 100%;

    textarea {
      font-size: 12px;
      height: 100% !important;
    }

    .MuiFormControl-root,
    .MuiInputBase-root {
      height: 100%;
    }
  }
}
