.intro {
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 100px;
  max-width: 1000px;
  text-align: center;
  position: relative;

  &__title {
    font-family: $font-sub;
    font-size: 60px;
    font-weight: 400;
    color: $yellow;
    letter-spacing: 0.018em;
    line-height: 60px;
  }
  
  &__download {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    margin-top: 40px;
    margin-bottom: 70px;
  }

  &__video {
    background-image: url($image-path + "/home/intro/video-frame.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    padding: 5px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    position: relative;
    gap: 60px;
    width: 1000px;
    height: 350px;
    margin-top: 80px;
    margin: 0 auto;

    &-description {
      position: relative;
      max-width: 400px;      
      text-align: justify;
      line-height: 22.88px;
      letter-spacing: 0.018em;
      padding: 20px 10px;
      font-size: 16px;
      font-weight: 400;
    }

    &-mode {
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      width: 60%;
      margin: 0 auto;

      font-family: $font-sub;
      font-size: 32px;
      font-weight: 400;

      div {
        cursor: pointer;
      }

      &--active {
        color: $yellow;
      }
    }

    &-switch {
      text-align: center;
      margin-bottom: 10px;

      div {
        margin-bottom: 10px;
      }
    }

    &-connector {
      flex-grow: 1;
      height: 5px;
      margin-bottom: 25px;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12" height="6"><image width="6" height="6" xlink:href="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgNiA2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cmVjdCB4PSIzLjAwMDI0IiB3aWR0aD0iNCIgaGVpZ2h0PSI0IiB0cmFuc2Zvcm09InJvdGF0ZSg0NSAzLjAwMDI0IDApIiBmaWxsPSIjNDIzRDM0Ii8+Cjwvc3ZnPgo=" /></svg>');
      background-repeat: repeat-x;
      background-position: center;
    }

    &-option {
      padding: 9px;

      &--active {
        padding: 0;
      }
    }

    &-divider {
      position: absolute;
      width: 351px;
      height: 1px;
      left: 250px;
      top: 170px;

      background: linear-gradient(90deg, #2A2935 0%, #E8DB9A 22.35%, #E8DB9A 73.18%, #2A2935 100%);
      transform: rotate(-90deg); 
    }
  }

  @media screen and (max-width: $breakpoint-1200) {
    max-width: 100%;
    padding: 20px;

    &__content {
      &-description {
        max-width: 400px;
      }

      max-width: 100%;
    }
  } 
  
  @media screen and (max-width: $breakpoint-1000) {
    &__video {
      min-height: 300px;
    }

    &__content {
      flex-direction: column;
      align-items: center;

      &-description {
        max-width: 98%;
      }

      &-divider {
        display: none;
      }
    }
  }
}
