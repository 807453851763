.header {
  background-image: url($image-path + "/header/header-frame.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: left;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  height: 83px;
  display: flex;

  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
  }

  &__logo {
    width: 85px;
    margin-top: 5px;
    margin-right: 15px;
  }

  &__menu {
    &-bars {
      padding: 10px;
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.7);
      cursor: pointer;
      display: none;
    }

    &-bar {
      width: 30px;
      height: 2px;
      margin-bottom: 7px;
      border-radius: 2px;
      background-color: white;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__links {
    display: flex;
    align-items: center;
    font-family: $font-sub;
    font-weight: 400;
    font-size: 24px;
  }

  &__link {
    position: relative;
    padding: 18px 15px;
    transition: all .2s;

    &.active,
    &:hover {
      background-image:
        url($image-path + "/decors/frames/appbar-select-top.svg"),
        url($image-path + "/decors/frames/appbar-select-bottom.svg");
      background-repeat: no-repeat;
      background-position: top, bottom;
    }

    &:hover &-dropdown {
      display: block;
    }

    &-dropdown {
      position: absolute;
      top: 50px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 206px;
      text-align: center;
      display: none;
      z-index: 1;
      background-image: url($image-path + "/header/dropdown-bottom.png");
      background-repeat: no-repeat;
      background-position: bottom;
      animation: all 0.3s;

      &--content {
        margin-top: 21px;
        margin-bottom: 7px;
        background-image: url($image-path + "/header/dropdown-frame.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        a {
          display: block;
          opacity: 0.8;

          &:hover {
            opacity: 1;
            transition: all 0.3s;
          }
        }
      }

      &--option {
        padding: 15px;
        border-bottom: 2px solid rgba(196, 196, 196, 0.2);

        &:hover {
          background-color: rgba(196, 196, 196, 0.2);
          transition: all 0.3s;
        }
      }

      &:hover {
        display: block;
      }
    }
  }

  &__button {
    display: flex;
  }

  &__data {
    display: flex;
    align-items: center;
    border-radius: 40px;
    padding: 8px 25px;
    box-shadow: $bs-4;
    background: $color-8;
    cursor: pointer;
  }

  &__token {
    display: flex;
    align-items: center;
    margin-right: 10px;

    div {
      margin-right: 5px;
    }
  }

  @media screen and (max-width: $breakpoint-1200) {
    background-image: none;

    &__container {
      flex-direction: row-reverse;
      justify-content: flex-start;
    }

    &__menu {
      margin-left: 20px;

      &-bars {
        display: block;
      }

      &.active {
        .header__links {
          transform: translateX(-250px);
        }

        .header__screen {
          width: 100%;
          height: 100vh;
          position: fixed;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.7);
          z-index: 98;
        }
      }
    }

    &__links {
      position: fixed;
      width: 250px;
      height: 100vh;
      right: -250px;
      top: 0;
      background: $color-7;
      padding: 10px 20px;
      flex-direction: column;
      z-index: 99;
      transition: all 0.3s;
      overflow-x: hidden;
      overflow-y: auto;
      font-size: 22px;
    }

    &__link {
      margin-right: 0;
      text-align: center;
      padding: 10px;

      &::after,
      &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        background: $yellow;
        left: 0;
        right: 0;
        margin: 0 auto;
        opacity: 0.4;
        transition: all .2s;
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }

      &.active,
      &:hover {
        width: 100%;
        color: $yellow;
        background: none;
        background-color: rgba(0, 0, 0, .2);

        &::after,
        &::before {
          width: 100%;
        }
      }
    }

    &__logo {
      margin: 20px 0 20px 0;
    }
  }

  @media screen and (max-width: $breakpoint-700) {
    justify-content: center;

    &__data {
      padding: 8px 15px;
    }
  }

  @media screen and (max-width: $breakpoint-375) {
    &__button {
      display: block;
      width: calc(100% - 70px);
    }

    .fw-4,
    &__token {
      margin-bottom: 8px;
    }

    &__data {
      border-radius: 5px;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding-bottom: 0;
    }
  }
}