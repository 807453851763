.synced-block {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 15px;
  top: -25px;

  &__circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: relative;
    top: 1px;
  }

  &__text {
    font-size: 11px;
    margin-right: 5px;
  }

  &--green {
    .synced-block__circle {
      background-color: $color-17;
    }
  }

  &--orange {
    .synced-block__circle {
      background-color: $color-15;
    }
  }
}