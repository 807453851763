.land {
  padding-top: 150px;

  &__header {
    display: flex;
    justify-content: space-between;

    &-buttons {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 25px;

      .btn {
        font-size: 10px;
        padding: 10px 15px;
      }
    }
  }

  &__preview {
    .lands__item-id {
      position: static;
      display: inline-block;
      padding: 4px 15px;
      font-size: 14px;
    }

    &-wrapper {
      position: relative;
      width: 100%;

      &-type {
        @extend .knight-preview__wrapper-race;
      }

      &-land {
        width: 100%;
      }
    }
  }

  &__claim {
    display: flex;
    align-items: center;
    gap: 50px;
    margin: 100px 0;

    img {
      width: 50%;
    }

    &-content {
      p {
        text-align: justify;
        color: #e5e5e5;
        vertical-align: top;
        margin-bottom: 20px;
        font-size: 16px;
      }
      h1 {
        color: #ffc904;
        text-transform: uppercase;
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 30px;
      }
    }

    &-accordion {
      background-color: transparent !important;
      box-shadow: none !important;
      &::before {
        display: none;
      }
      .MuiAccordionSummary-root {
        flex-direction: row-reverse;
        font-family: $font-sub;
        font-size: 30px;
        padding: 0;

        .MuiAccordionSummary-content {
          position: relative;
          top: 2px;
          margin-left: 10px;
        }
      }
    }
    &-link {
      color: #ffc904;
      text-decoration: underline;
      font-weight: 600;
    }
    ul {
      list-style-type: none;
      padding-left: 0;
    }
    li {
      color: #ababab;
      margin: 0;
      margin-bottom: 1em;
      position: relative;
      padding-left: 1.5em;

      &::after {
        content: "";
        height: 0.6em;
        width: 0.6em;
        background: #ffc904;
        display: block;
        position: absolute;
        border-radius: 50%;
        top: 0.4em;
        left: 0;
      }
    }
    &-requirements {
      color: #ababab !important;
    }
    &-btn {
      background: rgb(19, 19, 21);
      background: linear-gradient(54deg, rgba(19, 19, 21, 1) 0%, rgba(48, 19, 3, 1) 69%);
      border: 2px solid #67574f;
      color: #ffc904;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      padding: 20px;
      font-size: 24px;
      margin-top: 50px;
      transition: 0.2s;
      &:hover {
        cursor: pointer;
        background: #ffc904;
        color: #5d503d;
      }
    }
  }

  &__mint {
    background: url($image-path + "lands/land-definition-bg-1.png") no-repeat center;
    background-attachment: fixed;
    background-size: cover;

    &-fight {
      background: url($image-path + "lands/land-mint-1st-bg.png") no-repeat center {
        size: cover;
      }
      padding-top: 100px;

      &--img {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        max-width: 100%;
      }
    }
    &-stepper {
      background-color: transparent !important;

      &--step {
        .MuiStepConnector-line {
          border-top-width: 3px;
          border-color: #5d503d;
        }
        .MuiStepConnector-alternativeLabel {
          left: calc(-50% + 25px);
          top: 22px;
          right: calc(50% + 25px);
        }
        .MuiStepConnector-active {
          .MuiStepConnector-line {
            border-color: #b79259;
          }
        }
        .MuiStepLabel-iconContainer {
          background: url($image-path + "lands/land-stepper.png") no-repeat center {
            size: cover;
          }
          .MuiStepIcon-active,
          .MuiStepIcon-completed {
            background: url($image-path + "lands/land-active-stepper.png") no-repeat center {
              size: cover;
            }
          }
          .MuiStepIcon-root {
            color: transparent;
            width: 2em;
            height: 2em;
          }
          .MuiStepIcon-text {
            display: none;
          }
        }
        h1 {
          font-family: $font-base;
          font-size: 24px;
        }
      }
      &--phase {
        font-family: $font-base;
        color: #ffc904;
      }
    }

    &-drawer {
      .MuiPaper-root {
        background-color: black;
        font-family: $font-base;
        min-width: 250px;
        // border-left: 1px solid url($image-path + "lands/drawer-border.png");
        border-image: url($image-path + "lands/drawer-border.png") 0 0 0 100;
        border-style: solid;
        max-width: 400px;
      }

      &--container {
        padding: 0 30px;
        h1 {
          font-size: 30px;
          font-weight: bolder;
        }
        h3 {
          color: #c5a87e;
          font-weight: normal;
          margin-bottom: 20px;
        }
        p {
          font-size: 14px;
        }
        &--mobile {
          p {
            font-size: 14px;
          }
        }
      }
      &--mobile {
        margin-top: 20px;
        margin-bottom: 30px;
      }

      &-bg {
        &--mobile {
          animation: slide-left 0.7s forwards;
          animation-delay: 0.5s;
          width: 100%;
        }
      }

      &-name {
        &--mobile {
          font-family: "American Captain", sans-serif;
          font-size: 60px;
          font-weight: 400;
          line-height: 69px;
          letter-spacing: 0.018em;
          animation: slide-left 0.7s forwards;
          animation-delay: 0.5s;
        }
      }

      &-subtitle {
        &--mobile {
          color: #c5a87e;
          margin-bottom: 20px;
        }
      }
    }
    &-definition {
      padding: 50px 0;
      position: relative;

      .container {
        position: relative;
      }
      &--container {
        position: relative;
        display: flex;
        gap: 20px;
        align-items: center;
        img {
          width: 50%;
        }
      }

      &--content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        p {
          text-align: justify;
          color: #e5e5e5;
          vertical-align: top;
          font-size: 16px;
        }
        h1 {
          color: #ffc904;
          text-transform: uppercase;
          font-size: 30px;
          font-weight: bold;
        }
      }
    }
    &-benefit {
      text-align: center;
      margin-top: 50px;
      h1 {
        color: #ffc904;
        text-transform: uppercase;
        font-size: 30px;
        font-weight: bold;
      }
      p {
        text-align: justify;
        color: #e5e5e5;
        vertical-align: top;
        max-width: 650px;
        margin: 15px auto 30px;
        font-size: 16px;
      }
      &--img {
        max-width: 100%;
      }

      &--mobile {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 30px;
      }
    }

    &-thumbnail {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      &-icon {
        height: 90px !important;
        width: 90px !important;
        margin: 4px 12px;
        cursor: pointer;
        transition: all 0.1s;
        opacity: 0.7 !important;

        img {
          margin: 10px;
          width: 77%;
          height: 77%;
          object-fit: contain;
        }

        &:hover {
          opacity: 1 !important;
        }

        &--active {
          opacity: 1 !important;
          height: 94px !important;
          width: 94px !important;
          padding-left: 5px;
          margin: 0px 12px;
        }
      }
    }

    &-shadow {
      background: url($image-path + "lands/shadow.png") no-repeat center {
        size: cover;
      }
      padding: 100px 20px 0;
    }

    &-region {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      h1 {
        transition: 0.2s;
      }
      h3 {
        color: rgba(255, 244, 180, 0.8);
        font-size: 16px;
      }
      &:hover {
        h1 {
          color: $yellow;
        }
      }

      img {
        width: 20vw;
        max-width: 150px;
      }
    }
    &-map {
      background: url($image-path + "lands/map/base.png") no-repeat center;
      background-size: 100% 100%;
      margin: 0 auto;
      min-height: 800px;
      position: relative;
      display: grid;
      grid-template-columns: 30vw 30vw;
      grid-template-rows: auto;
      grid-column-gap: 50px;
      justify-content: center;

      &--ice {
        background: url($image-path + "lands/map/glacial.png") no-repeat center;
        background-size: 100% 100%;
      }

      &--fire {
        background: url($image-path + "lands/map/inferno.png") no-repeat center;
        background-size: 100% 100%;
      }
      &--water {
        background: url($image-path + "lands/map/aquatic.png") no-repeat center;
        background-size: 100% 100%;
      }
      &--earth {
        background: url($image-path + "lands/map/golem.png") no-repeat center;
        background-size: 100% 100%;
      }
    }

    &-overlay {
      position: absolute;
      top: 0;
      bottom: 10px;
      left: 0;
      right: 0;
      height: 55px;
      width: 55px;
      margin: auto;
      opacity: 0;
      border-radius: 50%;
      transition: 0.3s ease;

      &--ice {
        @extend .land__mint-overlay;
        background-color: #31f5d3;
      }
      &--water {
        @extend .land__mint-overlay;
        background-color: #63b7f4;
      }
      &--fire {
        @extend .land__mint-overlay;
        background-color: #f65b31;
      }
      &--earth {
        @extend .land__mint-overlay;
        background-color: #b37e4e;
      }
    }
  }

  &__buying {
    position: relative;
    margin: 50px 0 0;

    &-bg {
      position: absolute;
      max-width: 600px;
      bottom: 0;
      z-index: 0;
    }
    .container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;
      padding-bottom: 50px;
    }
    &-title {
      color: #ffc904;
      text-transform: uppercase;
      font-size: 30px;
      font-weight: bold;
      text-align: center;
    }

    &-desc {
      text-align: center;
      color: #e5e5e5;
      vertical-align: top;
      max-width: 650px;
      font-size: 16px;
      margin: 15px auto 30px;
    }

    &-table {
      max-width: 100%;
      border-collapse: separate !important;
      background: url($image-path + "lands/table-bg.png") no-repeat center;
      background-size: 100% 100%;
      padding: 10px 0;

      &--yellow {
        color: #ffc904;
        font-weight: bold;
      }

      &--subtitle {
        color: #ababab;
        font-size: 12px;
        margin-top: 10px;
      }
      &--container {
        overflow: auto;
        background-color: transparent;
        width: 100%;

        .MuiTableContainer-root {
          max-height: none;
          background-color: transparent !important;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-900) {
    &__buying {
      &-bg {
        display: none;
      }
    }
  }
  @media screen and (max-width: $breakpoint-700) {
    &__mint {
      &-fight {
        padding-top: 50px;
      }

      &-definition {
        &--container {
          flex-direction: column;
          margin-bottom: 30px;
          img {
            width: 60%;
            min-width: 300px;
          }
        }
      }
    }

    &__claim {
      gap: 30px;
      flex-direction: column-reverse;
      img {
        width: 60%;
        min-width: 300px;
      }
    }
  }
}
