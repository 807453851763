.help {
  max-width: 1400px;
  padding: 20px 16px;
  margin: 0 auto;

  &__links {
    transition: 0.3s;
    cursor: pointer;
    text-decoration: underline;
    
    &:hover {
      filter: brightness(120%);
      color: $color-17;
    }
  }
  &__title {
    font-size: 40px;
    color: $yellow;
    text-shadow: 0 1px 4px #131212;
    font-family: $font-sub;
    text-align: center;
    margin-bottom: 20px;
  }

  &__search {
    width: 100%;
    background-color: $color-8;
    border-radius: 20px;
    padding: 25px 100px 25px 100px;
    display: flex;
    flex-direction: row;

    &-autocomplete {
      width: 100%;

      &--paper {
        background-color: $color-8;
      }
      &.MuiAutocomplete-option {
        &:hover {
          background-color: $color-17;
        }
      }
      &--options {
        font-size: 16px;
        margin: 10px 10px 10px 0;
        font-family: $font-base;
        font-weight: 500;
      }
    }

    &-bar {
      background-color: white;
      padding: 15px;
      border-radius: 12px;
      color: black;
      font-size: 18px;
      transition: all 0.3s;
      font-family: $font-base;

      &::after,
      &::before {
        display: none;
      }
    }

    &-btn {
      width: 130px;
      min-width: 50px;
      background-color: $yellow;
      color: white;
      border-radius: 12px;
      padding: 25px;
      margin-left: 20px;
      font-family: $font-base;

      &:hover {
        background-color: #ffe071;
      }
    }
  }

  &__chips {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 30px 30px 100px;
  }

  &__chip {
    color: black;
    background-color: white;
    border-radius: 4px;
    border: 2px solid #e8eaee;
    margin-right: 20px;
    font-family: $font-base;
    font-size: 14px;

    .MuiChip-deleteIcon {
      color: black;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__list {
    background-color: $color-8;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    height: fit-content;
    width: 25%;

    &-item {
      border-bottom: 1px solid #52515d;
      height: 65px;

      &:hover {
        background-color: $color-17;
        border-bottom: 1px solid transparent;
      }
      span {
        font-family: $font-base;
        font-size: 16px;
        font-weight: 700;
      }
    }

    .Mui-selected {
      background-color: $color-17;
      border-bottom: 1px solid transparent;

      &:hover {
        background-color: $color-17;
        border-bottom: 1px solid transparent;
      }
    }
  }

  &__accordions {
    width: 70%;
    background-color: $color-8;
    padding-top: 20px;
    border-radius: 10px;
    height: fit-content;
    .MuiAccordion-root.Mui-expanded {
      margin: 0px;
    }
  }

  &__accordion {
    background-color: $color-8;
    margin: 0;

    .MuiAccordionSummary-root {
      min-height: 65px;
    }

    .MuiAccordionSummary-content .MuiTypography-root {
      font-family: $font-base;
      font-size: 16px;
      font-weight: 700;
    }

    &-details {
      font-family: $font-base;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      padding: 20px;
      word-break: break-word;
    }

    .MuiAccordionSummary-root.Mui-expanded {
      background-color: $color-17;
    }

    .MuiCollapse-root {
      background-color: #24977a;
      font-family: $font-base;
    }
  }

  @media screen and (max-width: $breakpoint-959) {
    &__search {
      flex-direction: column;
      align-items: center;
      padding: 25px;

      &-bar {
        width: 100%;
      }

      &-btn {
        width: 100%;
        padding: 10px;
        margin: 20px 0;
      }
    }

    &__chips {
      margin: 20px 0 20px 10px;
    }

    &__chip {
      margin: 0 10px 10px 0;
    }

    &__content {
      flex-direction: column;
      align-items: center;
    }

    &__list,
    &__accordions {
      margin-bottom: 50px;
      width: 100%;
    }
  }
}
