.stats {
  &__allocation {
    display: flex;
    gap: 30px;

    &-cap {
      margin-top: 30px;
      width: 311px;
    }

    .MuiTableCell-root {
      padding: 10px;
    }
  }

  &__skill {
    &-effect {
      font-size: 16px;
      line-height: 150%;
      color: $color-9;
      white-space: pre-line;
    }
  }

  &__title {
    font-family: $font-sub;
    font-size: 28px;
    font-weight: 400;
    margin-right: 15px;

    &-bonus {
      font-size: 20px;
      color: $color-17;
      position: relative;
      bottom: 3px;
    }
  }

  &__btn {
    padding: 7px 8px;
    font-size: 10px;

    &-container {
      display: flex;
      align-items: center;
      column-gap: 12px;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  &__item {
    text-align: center;

    &:last-child {
      margin-right: 0;
    }
  }

  &__description {
    font-size: 14px;
    margin-top: 5px;
    line-height: 21px;
    color: $gray;
  }

  &__name {
    font-size: 16px;
    font-weight: 500;
    color: $gray;
  }

  &__value {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;

    img {
      width: 35px;
      margin-right: 3px;
      position: relative;
      bottom: 1px;
    }
  }

  @media screen and (max-width: $breakpoint-800) {
    &__allocation {
      flex-direction: column-reverse;

      .MuiTableCell-root {
        font-size: 14px;
      }

      &-cap {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: $breakpoint-600) {
    &__container {
      flex-wrap: wrap;
      margin-top: 20px;
    }

    &__item {
      width: 45%;
      margin-bottom: 20px;
    }

    &__name {
      text-align: start;
      font-size: 12px !important;
      margin-bottom: 10px;
    }

    &__value {
      justify-content: flex-start;
    }
  }

  @media screen and (max-width: $breakpoint-500) {
    .flex-center-start{
      flex-direction: column;
      align-items: flex-start;
    }

    &__btn {
      &-container {
        margin-top: 15px;
      }
    }
  }
}