.guild-icon {
  &__frame {
    width: 200px;
    height: 200px;
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 135px;
      height: 100%;
      top: -20px;
      left: 11px;
      background-image: url($image-path + "guild/guild-image-border.png");
    }
    &--marketplace {
      width: 170px;
      height: 170px;
    }

    &--large {
      &::before {
        position: relative;
        width: 220px;
        height: 220px;
        top: 0;
        left: 0;
      }
    }
  }

  &__preview {
    width: 192px;
    height: 192px;
    position: absolute;
    left: 18px;
    right: 0;
    margin: 0 auto;
    border-radius: 50%;
    top: 65px;
    transform: translateY(-50%);
    object-fit: cover;

    &--marketplace {
      width: 120px;
      height: 120px;
    }

    &--large {
      top: 110px;
      left: 14px;
    }
  }

  @media screen and (max-width: $breakpoint-700) {
    &__frame {
      &--large {
        &::before {
          left: -10px;
          right: 0;
        }
      }

    }

    &__preview {
      &--large {
        left: 0;
        right: 0;
      }
    }
  }
  @media screen and (max-width: $breakpoint-600) {
    &__frame {
      &--marketplace {
        &::before {
          width: 150px;
          top: 0;
          left: 20px;
        }
      }

    }

    &__preview {
      &--marketplace {
        width: 130px;
        top: 85px;
        left: 30px;
      }
    }
  }
}
