.wallet {
  &__tab {
    span {
      font-family: $font-base;
      font-weight: 600;
      width: fit-content;
    }

    .MuiTab-root {
      min-width: 0 !important;
    }

    button {
      border-radius: 8px;
      border: 1px solid $color-9;
      transition: 0.2s;
      padding: 0px 10px;

      &:hover {
        background-color: $color-9;
      }
    }

    .MuiTabs-indicator {
      display: none;
      bottom: 3px;
      background-color: $color-17;
    }

    .Mui-selected {
      background-color: $color-9;
    }

    .MuiTabs-flexContainer {
      gap: 10px;
    }

    &--assets {
      @extend .wallet__tab;
      max-width: 480px;
    }

    &-icon {
      width: 16px;
      height: 24px;
    }
  }

  &__btn {
    padding: 10px 10px 9px;
    border-radius: 5px;
  }

  &__inventory {
    width: 50%;

    &-container {
      @extend .container;
      gap: 5px
    }
  }
  .inventory {
    padding: 25px 15px 0;
    height: 340px;
    margin-top: 15px;
    display: block;
  }

  .knights {
    margin-top: -15px;
  }

  .knight__title {
    margin-bottom: 0;
    margin-top: 10px;
  }

  &__deposit {
    transition: 0.2s;
    &:hover {
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
  @media screen and (max-width: $breakpoint-1200) {
    .flex {
      flex-direction: column;
    }
    &__inventory {
      width: 100%;
    }
  }

  @media screen and (max-width: $breakpoint-800) {
    .flex-center-between {
      display: block;
    }

    &__tab {
      margin: 20px 0;
    }
  }
}
