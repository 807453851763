.material-list {
  &__images {
    position: relative;
    z-index: 1;
  }

  &__highlight {
    width: 110px;
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0.6;
  }

  &__item {
    display: flex;
    justify-content: center;
    position: relative;

    &--placeholder {
      width: 100%;
    }

    &-info {
      font-size: 10px;
      line-height: 14px;
      font-weight: 400;

      &--name {
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  &__content {
    .flex-column {
      &:first-child {
        flex: 2;
      }
    }
  }

  &__price {
    font-size: 24px;
    font-family: $font-sub;
    display: flex;
    align-items: center;
    position: relative;
    bottom: 5px;

    &--converted {
      font-family: "Montserrat", sans-serif;
      font-size: 12px;
      color: #ababab;
    }

    .token {
      width: 20px;
      height: 20px;
    }
  }

  @media screen and (max-width: $breakpoint-1200) {
    &__content {
      flex-direction: column;
      margin-left: 10px;
    }

    &__highlight {
      display: none;
    }

    &__item {
      width: 150px;

      &--placeholder {
        width: 150px;
      }

      &-info {
        &--name {
          font-size: 16px;
        }

        font-size: 12px;
        line-height: 20px;

        &:last-child {
          margin-bottom: 10px;
        }
      }
    }

    &__price {
      .token {
        width: 20px;
        height: 24px;
      }

      font-size: 30px;
      margin-top: 10px;
      &--converted {
        font-size: 14px;
      }
    }

    &__rarity {
      top: 12px;
      right: auto;
      border-radius: 3px;
    }
  }
}
