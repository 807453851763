.item-list {
  &__images {
    position: relative;
    z-index: 1;
  }

  &__highlight {
    width: 110px;
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0.6;
  }

  &__timer {
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__item {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    position: relative;

    &--placeholder {
      width: 100%;
    }

    &--black-market {
      margin-top: 40px;
    }
    &-info {
      font-size: 10px;
      line-height: 14px;
      font-weight: 400;

      &--name {
        font-size: 12px;
        font-weight: 700;
      }

      &--black-market {
        font-family: $font-sub;
        font-size: 25px;
      }
    }
  }

  &__rarity {
    position: absolute;
    top: 20px;
    right: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 3px 10px;
    font-size: 14px;
    font-weight: 700;
    z-index: 9;

    &.common {
      background-color: $color-common;
    }

    &.uncommon {
      background-color: $color-uncommon;
    }

    &.rare {
      background-color: $color-rare;
    }

    &.epic {
      background-color: $color-epic;
    }

    &.legendary {
      background-color: $color-legendary;
    }

    &.mythical {
      background-color: $color-mythical;
    }
  }

  &__content {
    display: flex;

    &--black-market {
      align-items: center;
    }
    .flex-column {
      &:first-child {
        flex: 2;
      }
    }
  }

  &__price {
    font-size: 24px;
    font-family: $font-sub;
    display: flex;
    margin-bottom: 1px;

    &--converted {
      font-family: "Montserrat", sans-serif;
      font-size: 12px;
      color: #ababab;
    }

    &--black-market {
      align-items: center;
    }
  }

  @media screen and (max-width: $breakpoint-1200) {
    &__content {
      flex-direction: column;
      margin-left: 10px;
      margin-top: 25px;
      &--black-market {
        align-items: flex-start;
      }
    }

    &__highlight {
      display: none;
    }

    &__item {
      width: 150px;
      margin-top: 10px;

      &-info {
        &--name {
          font-size: 14px;
        }

        font-size: 12px;
        line-height: 20px;

        &:last-child {
          margin-bottom: 10px;
        }
      }
    }

    &__price {
      font-size: 30px;

      &--converted {
        font-size: 14px;
      }
    }

    &__rarity {
      top: 12px;
      right: auto;
      border-radius: 3px;
    }
  }

  @media screen and (max-width: $breakpoint-414) {
    &__content {
      margin-top: 35px;
    }

    &__item {
      margin-top: 5px;
    }
  }
}
