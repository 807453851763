.material-icon {
  width: 100%;
  max-width: 70px;
  display: inline-block;
  margin: 0 15px 12px 0;
  text-align: center;
  cursor: pointer;
  position: relative;
  border-radius: 4px;

  &__header {
    position: relative;
    bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -30px;

    .material-icon__frame {
      right: 22px;
      width: 150px;
      height: 150px;
    }
  }

  &__image {
    width: 100%;
    border: 3px solid $color-14;

    &:hover {
      border-color: $color-9;
    }

    &.active {
      border-color: $color-9 !important;
    }
  }

  &--selected .material-icon__image {
    border-color: $color-9;
  }

  &__frame {
    width: 400px;
    height: 400px;
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      height: 106%;
      top: -14px;
      z-index: 1;
      left: 0;
    }

    &--common::before {
      background-image: url($image-path + "decors/frames/materials/frame-common.png");
    }

    &--uncommon::before {
      background-image: url($image-path + "decors/frames/materials/frame-uncommon.png");
    }

    &--rare::before {
      background-image: url($image-path + "decors/frames/materials/frame-rare.png");
    }

    &--epic::before {
      background-image: url($image-path + "decors/frames/materials/frame-epic.png");
    }

    &--legendary::before {
      background-image: url($image-path + "decors/frames/materials/frame-legendary.png");
    }

    &--mythical::before {
      background-image: url($image-path + "decors/frames/materials/frame-mythical.png");
    }

    &--marketplace {
      width: 200px;
      height: 180px;
      margin-bottom: 10px;

      &::before {
        content: "";
        display: block;
        position: absolute;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 120%;
        z-index: 1;
        top: -20px;
        left: 0;
      }
    }

    &--modal {
      width: 400px;
      height: 400px;
      position: relative;

      &::before {
        content: "";
        display: block;
        position: absolute;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 106%;
        top: 0;
        z-index: 1;
        left: 0;
      }
    }

    &--rune {
      transition: 0.2s;
      cursor: pointer;
      z-index: 1;

      &::before {
        content: "";
        display: block;
        position: absolute;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }
  }

  &__preview {
    width: 70%;
    height: 66%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: -1;
    top: 52%;
    transform: translateY(-50%);

    &--marketplace {
      height: auto;
    }

    &--popup {
      top: 46%;
    }

    &--modal {
      z-index: unset;
      width: 66%;
      height: 70%;
    }

    &--rune {
      width: 66%;
      height: 66%;
      background-color: $color-14;
    }
  }

  &__available {
    position: absolute;
    font-family: $font-sub;
    font-size: 20px;
    bottom: 17%;
    right: 20%;
  }

  &__info {
    width: calc(100% - 135px);
    position: absolute;
    right: 0;
  }

  &__quantity {
    position: absolute;
    bottom: 17px;
    right: 20px;
    font-family: $font-sub;
    font-size: 22px;
    letter-spacing: 1.2px;
    -webkit-text-stroke: 1px black;
  }

  &__amount {
    position: absolute;
    font-weight: 500;
    background: $color-18;
    border-radius: 50%;
    padding: 6px;
    font-size: 11px;
    bottom: -2px;
    right: -6px;
    color: white;
    z-index: 1;
  }

  &__text {
    font-size: 11px;
    margin-top: 5px;
  }

  &__btn {
    font-size: 10px;
    padding: 3px 7px 2px;
    text-transform: initial;
    border-radius: 3px;
    margin-left: 10px;
    position: relative;
    bottom: 3px;
  }

  @media screen and (max-width: $breakpoint-1200) {
    &__gem {
      &-marketplace {
        &--0 {
          bottom: 18.4%;
        }

        &--1 {
          left: 53%;
          bottom: 18.4%;
        }

        &--2 {
          left: 45%;
          bottom: 17.4%;
        }
      }
    }

    &__available {
      bottom: 25%;
    }
  }

  @media screen and (max-width: $breakpoint-414) {
    &__frame {
      width: 250px;
      height: 250px;
    }
  }

  @media screen and (max-width: $breakpoint-414) {
    margin: 0 8px 12px 0;

    &__header {
      display: block;
    }

    &__frame {
      right: 0 !important;
      margin: 0 auto;
    }

    &__info {
      width: 100%;
      text-align: center;
      position: initial;
      margin: 10px 0 35px;
    }
  }
}
